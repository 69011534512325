import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Color, COLOR, NormalCircle } from '@seech/shared-ng';

import { ThemeColorDirective } from '../../directives';
import { CommonModule } from '@angular/common';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  AbstractControl,
  ValidationErrors,
  Validators,
  ReactiveFormsModule,
  FormsModule,
  ControlValueAccessor,
  Validator,
} from '@angular/forms';
import { ControlsConfigService } from '../../injection/controls-config.service';

@Component({
  standalone: true,
  selector: 'seech-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  imports: [
    CommonModule, 
    MdbCheckboxModule, 
    ThemeColorDirective,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor, Validator, OnChanges {
  @Input() color: Color = COLOR.DANGER;
  @Input() disabled = false;
  @Input() id = '';
  @Input() label = '';
  @Input() name = '';
  @Input() selected!: any;
  @Input() value!: any;
  @Input() type?: 'checkbox' | 'radio' = 'checkbox';
  @Input() shape!: NormalCircle;

  @Output() selectedChange: any = new EventEmitter<any>();

  constructor(private configService: ControlsConfigService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['shape'] && !this.shape) {
      const config = this.configService.getConfig();
      const shape = this.type === 'radio' ? config.radioButtonShape : config.checkboxShape;
      this.shape = shape ? shape : this.type == 'radio' ? 'circle' : 'normal';
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  private onChange = (value: any) => { };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => { };

  onCheckboxChange(): void {
    // this.selected = (event.target as HTMLInputElement).checked;

    this.selectedChange.emit(this.selected);
    this.onChange(this.selected);
    this.onTouched();
  }

  writeValue(value: any): void {
    this.selected = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(control: AbstractControl): ValidationErrors | null {
    // return this.selected ? null : { required: true };
    const valueIsRequired = control.hasValidator(Validators.required);
    const requiredError = { required: true };
    if (!this.selected && valueIsRequired) {
      return requiredError;
    }
    return null;
  }
}
