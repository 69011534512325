import {
  ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, Inject, Input, OnChanges, OnInit, Output, PLATFORM_ID,
  Renderer2, SimpleChanges, forwardRef, ViewChild, AfterViewChecked, ChangeDetectionStrategy
} from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Color, ChipComponent, ProgressBarComponent as SharedProgressBarComponent, SharedScrollEndNotifierDirective, IconDirective } from '@seech/shared-ng';
import { CharacterLimitDirective } from '../../directives';

@Component({
  selector: 'seech-combo-select',
  standalone: true,
  imports: [
    CommonModule,
    AutocompleteComponent,
    ChipComponent,
    MdbFormsModule,
    ReactiveFormsModule,
    FormsModule,
    SharedProgressBarComponent,
    SharedScrollEndNotifierDirective,
    IconDirective,
    CharacterLimitDirective
  ],
  templateUrl: './combo-select.component.html',
  styleUrls: ['./combo-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ComboSelectComponent),
      multi: true,
    },
  ],
})
export class ComboSelectComponent implements AfterViewChecked, OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {
  @ViewChild('comboInput', { static: true }) comboInput!: ElementRef;
  @ViewChild('pillContainer', { static: false }) pillContainer!: ElementRef;

  @Input({ required: true }) results: any[] = [];
  @Input() value: string | string[] | any = '';
  @Input() chipsColor = "info";
  @Input() placeHolder = ""
  @Input() readonlyplaceholder = ""
  @Input() label?: string;
  @Input() debounceTime = 500;
  @Input() loading = false;
  @Input() progressBarColor: Color = 'secondary';
  @Input({ required: true }) id: string | number | null | undefined;
  @Input() hideBorder = false;
  @Input() maxRowCount = 1;
  @Input() rowHeight = 50; // Adjust based on your row height
  @Input() inputValue = '';
  @Input() autoSelect = false;
  @Input() readonly = false;
  @Input() disabled = false;
  // @Input() controlType: 'combo-select' | 'location' = 'combo-select';
  @Input() maxSelectionCount = 1;
  @Input() threshold = 100;
  @Input() iClass = '';
  @Input() autocomplete = 'off';
  @Input() iStyle: { [key: string]: string } = {};
  @Input() showSelected = false;
  @Input() characterLimit?: number; // Optional character limit
  @Input() characterLimitTreshold = 80; // Default threshold for character limit
  @Input() showCharacterCountElement = false; // Flag to show character count element
  @Input() exceedCharacterLimit = false; // Flag to allow characters exceeding the character limit
  @Input() chipCancelable = true; // Flag to allow canceling chips


  @Output() valueChange = new EventEmitter<any>();
  @Output() debouncedInput = new EventEmitter<string>();
  @Output() selectedChange = new EventEmitter<string[] | any[] | object>();
  @Output() chipsDeleteChange = new EventEmitter<string[] | any[] | object>();
  @Output() searchTextChanged = new EventEmitter<string>();
  @Output() blurred = new EventEmitter<any>();
  @Output() selectionLimitReached = new EventEmitter<boolean>();
  @Output() scrollEnd = new EventEmitter<void>();
  @Output() characterLimitReached = new EventEmitter<void>(); // Emit when character limit is reached
  @Output() characterLimitExceeded = new EventEmitter<boolean>(); // Emit when character limit is exceeded


  showDropdown = false;
  selectedOptionsArray: any[] = [];
  notFound = false;
  maxHeight!: number;
  showActionIcon = false;

  private clickListener?: () => void;
  private inputChanged = new Subject<string>();
  private destroy$ = new Subject<void>();

   
  private onChange = (value: any) => { };
   
  private onTouched = () => { };

  constructor(
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    @Inject(Renderer2) private renderer: any,
    @Inject(PLATFORM_ID) private platformId: object,
  ) { }

  ngOnInit(): void {
    this.initializeSelectedOptions();
    this.inputChanged.pipe(debounceTime(this.debounceTime), takeUntil(this.destroy$)).subscribe(value => {
      this.debouncedInput.emit(value);
    });

    if (isPlatformBrowser(this.platformId)) {
      this.clickListener = this.renderer.listen('document', 'click', (event: Event) => {
        this.onDocumentClick(event);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['results'] || changes['value']) {
      this.maxHeight = this.maxRowCount * this.rowHeight;
      this.initializeSelectedOptions();
      this.cdr.markForCheck();
    }
  }

  optionSelected(option: any): boolean {
    return this.selectedOptionsArray.some(x => x.name.includes(option.name));
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  ngOnDestroy(): void {
    if (this.clickListener) {
      this.clickListener();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.value = value;
    this.onChange(value);
    this.onTouched();
  }

  blurr(event: any) {
    this.blurred.emit(event.target.value);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const valueIsRequired = control.hasValidator(Validators.required);
    const requiredError = { required: true };
    if (this.selectedOptionsArray.length === 0 && valueIsRequired) {
      return requiredError;
    }
    return null;
  }
  
  prefill = true
  initializeSelectedOptions(): void {
    if (this.prefill) {
      this.selectedOptionsArray = [];
      let valuesToSelect: any[] = [];

      if (Array.isArray(this.value) && this.value.length > 0) {
        // If value is a non-empty array, use it directly
        valuesToSelect = this.value;
      } else if (typeof this.value === 'string' && this.value.trim().length > 0) {
        // If value is a non-empty string, split it into an array of strings
        valuesToSelect = this.value.split(',').map(v => v.trim()).filter(v => v.length > 0);
      }

      valuesToSelect.forEach((val: any) => {
        let optionToAdd: any = null;

        if (typeof val === 'object' && val !== null && 'name' in val) {
          // If val is an object and has a 'name' property
          optionToAdd = val;
        } else if (typeof val === 'string') {
          // If val is a string, create a new option object or match existing option
          const matchingOption = this.results?.find(option => option.name === val);
          optionToAdd = matchingOption || { name: val, value: val };
        }

        if (optionToAdd) {
          this.selectedOptionsArray = [...this.selectedOptionsArray, optionToAdd];
        }
      });

      // Only update input if there are valid selected options
      if (this.selectedOptionsArray.length > 0) {
        this.updateInputFromSelected();
      }
    }
  }


  onInputChanged(event: any): void {


    this.inputChanged.next(event.target.value);
    this.inputValue = event.target.value;

    const inputExists = this.selectedOptionsArray.some(
      option => option.name.toLowerCase() === this.inputValue.toLowerCase()
    );

    // Show the action icon only if the input is editable and it's value doesn't 
    // exist in the selected options array and is not empty
    if (!this.readonly && !this.disabled) {
      this.showActionIcon = !inputExists && this.inputValue.trim().length > 0;
    }

    if (this.selectedOptionsArray.length <= this.maxSelectionCount) {
      this.selectionLimitReached.emit(false);
    }

    this.searchTextChanged.emit(event.target?.value);
  }






  onCheckboxChange(option: any): void {
    this.prefill = false;

    // Check if the option already exists in the selected options array
    const isDuplicate = this.selectedOptionsArray.some(
      selectedOption => selectedOption.name.toLowerCase() === option.name.toLowerCase()
    );

    if (isDuplicate) {
      // Prevent duplicate selection
      return;
    }

    // Add the option if it's not a duplicate
    this.selectedOptionsArray.push(option);
    this.updateInputFromSelected();
    this.selectedChange.emit(this.selectedOptionsArray);
    this.inputValue = '';
    this.showDropdown = false;
    this.cdr.detectChanges();
  }


  removePill(option: any): void {
    this.prefill = false
    this.selectedOptionsArray = this.selectedOptionsArray.filter(item => item !== option);

    if (this.selectedOptionsArray.length <= this.maxSelectionCount) {
      this.selectionLimitReached.emit(false);
    }

    this.valueChange.emit(this.selectedOptionsArray);
    this.chipsDeleteChange.emit(this.selectedOptionsArray);
  }

  onDocumentClick(event: Event): void {
    if (!this.el.nativeElement.contains(event.target as Node)) {
      this.showDropdown = false;
      this.cdr.detectChanges();
    }
  }


  handleEnterKey(event: any): void {
    event.preventDefault();
    this.addInputValueToSelectedOptions();
  }


  handleTabKey(event: any): void {
    event.preventDefault();
    this.addInputValueToSelectedOptions();
  }



  addInputValueToSelectedOptions(): void {
    const inputValue = this.inputValue?.trim();
    if (inputValue) {
      const isDuplicateValue = this.selectedOptionsArray.some(
        option => option.name.toLowerCase() === inputValue.toLowerCase()
      );

      if (isDuplicateValue) {
        this.inputValue = ''; // Clear the input field
        return;
      }

      if (this.maxSelectionCount === 1) {
        this.selectedOptionsArray = []; // Clear the existing value if max selection count is 1
      } else if (this.selectedOptionsArray.length >= this.maxSelectionCount && this.maxSelectionCount > 1) {
        this.selectionLimitReached.emit(true);
        return;
      }

      const existingOption = this.results.find(option => option.name.toLowerCase() === inputValue.toLowerCase());
      const newOption = existingOption || { name: inputValue, value: inputValue };

      this.selectedOptionsArray.push(newOption);
      this.inputValue = ''; // Clear the input field
      this.showDropdown = false;
      this.valueChange.emit(this.selectedOptionsArray);
      this.selectedChange.emit(this.selectedOptionsArray);
    }
  }



  updateInputFromSelected(): void {

    this.valueChange.emit(this.selectedOptionsArray);
  }

  scrollToBottom(): void {
    if (this.pillContainer && this.pillContainer.nativeElement) {
      this.pillContainer.nativeElement.scrollTop = this.pillContainer.nativeElement.scrollHeight;
    }
  }

  trackByFn(index: number, item: any): any {
    return item.value;
  }

  onScrollEnd() {
    this.scrollEnd.emit();
  }
  onCharacterLimitReached() {
    this.characterLimitReached.emit();
  }
  onCharacterCountExceeded(e) {
    // console.log('Character limit exceeded!');
    this.characterLimitExceeded.emit(e);
  }
}
