import { CommonModule } from '@angular/common';

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges
} from '@angular/core';
import { TabComponent } from './tab.component';
import { IconDirective } from '@seech/shared-ng';

@Component({
  selector: 'seech-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconDirective
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent implements AfterContentInit, OnChanges {
  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;
  @Input() fill = false;
  @Input() justified = false;
  @Input() activeTab?: number;
  @Input() tabIcon: any = '';
  @Input() inactiveTabBorderBottom = false;
  @Input() activeTabStyle: ActiveTabStyle = {};
  @Output() tabChanged: EventEmitter<TabChangedEvent> = new EventEmitter<TabChangedEvent>();

  ngAfterContentInit() {
    const indexToSelect = this.activeTab !== undefined && this.activeTab < this.tabs.length ? this.activeTab : 0;
    this.selectTab(indexToSelect, false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('activeTab' in changes) {
      const indexToSelect = this.activeTab !== undefined && this.activeTab < this.tabs?.length ? this.activeTab : 0;
      this.selectTab(indexToSelect, false);
    }
  }

  selectTab(index: number, notifyListeners = true) {
    this.tabs?.forEach((tab, i) => (tab.active = i === index));
    if(notifyListeners){
      const activeTab: TabChangedEvent = {
        index: index,
        tab: this.tabs?.toArray()[index]
      };
      this.tabChanged.emit(activeTab);
    }
  }

  isString(value: any): boolean {
    return typeof value === 'string';
  }
}

interface TabChangedEvent {
  index: number;
  tab: TabComponent;
}
interface ActiveTabStyle {
  backgroundColor?: string;
  borderBottomColor?: string;
  hoverColor?: string;
}
