import { Directive, HostBinding } from '@angular/core';
import { CdkMenuItem } from '@angular/cdk/menu';

@Directive({
  selector: '[sch-menu-item]',
  standalone: true,
  providers: [
    {
      provide: CdkMenuItem,
      useExisting: MenuItemDirective,
    },
  ],
})
export class MenuItemDirective extends CdkMenuItem {
  @HostBinding('class') hostClass = 'sch-menu-item';
}
