import { Injectable } from '@angular/core';
import {
  MdbModalRef,
  MdbModalService,
  MdbModalConfig,
} from 'mdb-angular-ui-kit/modal';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalRefs: MdbModalRef<any>[] = [];
  modalComponent: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private initialConfig: ModalConfig = {
    animation: true,
    backdrop: true,
    containerClass: 'modal-container', // Default container class
    data: {},
    ignoreBackdropClick: false,
    keyboard: true,
    modalClass: 'modal-dialog', // Default modal class
    nonInvasive: false, // Default to MDB modal behavior
  };

  constructor(private modalService: MdbModalService) {}

  open(componentType: any, config?: ModalConfig): MdbModalRef<any> {
    const mdbConfig: MdbModalConfig = {
      ...this.initialConfig, // Default options
      ...config, // Override default options with the provided options
    };

    const modalRef: MdbModalRef<any> = this.modalService.open(
      componentType,
      mdbConfig
    );

    this.modalRefs.push(modalRef); // Store the modal reference
    this.modalComponent.next(modalRef.component);

    return modalRef;
  }

  close(modalRef: MdbModalRef<any> | null = null, data: any = null): void {
    if (modalRef) {
      // Close the specific modal
      const index = this.modalRefs.indexOf(modalRef);
      if (index !== -1) {
        this.modalRefs[index].close(data);
        this.modalRefs.splice(index, 1);
      }
    } else if (this.modalRefs.length > 0) {
      // Close the most recently opened modal
      const ref = this.modalRefs.pop();
      ref && ref.close(data);
    }
  }

  closeAll(data: any = null): void {
    // Close all open modals and pass data when they're closed
    this.modalRefs.forEach(modalRef => modalRef.close(data));
    this.modalRefs = [];
  }

  // Get the modal component
  modalRefComponent(): BehaviorSubject<any> {
    return this.modalComponent;
  }
}

export interface ModalConfig {
  animation?: boolean;
  backdrop?: boolean;
  containerClass?: string;
  data?: any;
  ignoreBackdropClick?: boolean;
  keyboard?: boolean;
  modalClass?: string;
  nonInvasive?: boolean;
}
