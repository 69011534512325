<div
  class="sch-combo-select sch-input"
  [attr.disabled]="disabled"
  [attr.readonly]="readonly"
>
  <label
    *ngIf="label"
    class="sch-input-label classic"
    for="{{ id }}"
    [attr.readonly]="readonly"
    [attr.disabled]="disabled"
  >
    {{ label }}
  </label>

  <div
    [ngClass]="{ 'sch-combo-field-border': !hideBorder }"
    class="w-100 position-relative"
  >
    <!-- Pill container for selected options -->
    <div
      *ngIf="selectedOptionsArray.length > 0"
      class="pill-container"
      #pillContainer
      [ngStyle]="{ 'max-height.px': maxHeight }"
    >
      <seech-shared-chip
        *ngFor="let option of selectedOptionsArray"
        [description]="option?.name"
        [color]="chipsColor"
        [fill]="'solid'"
        (cancel)="disabled || readonly ? null : removePill(option)"
        [createAvatar]="false"
        [cancelable]="chipCancelable"
      ></seech-shared-chip>
    </div>

    <div
      class="position-relative d-flex align-items-center w-100"
      id="input-container"
    >
      <input
        #comboInput
        (focus)="showDropdown = true"
        (input)="onInputChanged($event)"
        [placeholder]="readonly ? readonlyplaceholder : placeHolder"
        [value]="inputValue"
        class="w-50 w-100 sch-combo-input"
        [id]="id"
        (blur)="blurr($event)"
        (keyup.enter)="handleEnterKey($event)"
        (keydown.tab)="handleTabKey($event)"
        [readonly]="readonly"
        [disabled]="disabled"
        [autocomplete]="autocomplete"
      />

      <div class="add-button" *ngIf="showActionIcon && !(disabled || readonly)">
        <i [sch-icon]="'plus'" (click)="addInputValueToSelectedOptions()"></i>
      </div>
    </div>

    <!-- Dropdown for showing filtered options with checkboxes -->
    <div
      sch-scroll-end-notifier
      [offset]="threshold"
      (scrollEnd)="onScrollEnd()"
      *ngIf="showDropdown && results.length > 0"
      class="sch-combo-select-dropdown"
      [ngClass]="{ bordered: !hideBorder }"
    >
      <ng-container *ngFor="let option of results; let i = index">
        <div
          *ngIf="!(!showSelected && optionSelected(option))"
          (click)="onCheckboxChange(option)"
          class="d-flex sch-combo-check w-100 p-2"
        >
          <label for="checkbox-{{ i }}" class="w-100">
            <div class="mx-2"></div>
            <div class="mx-2 w-75">
              <span
                class="sch-comobo-label sch-option-value"
                [class.text-muted]="optionSelected(option)"
              >
                {{ option.name }}
              </span>
            </div>
          </label>
        </div>
      </ng-container>

      <div *ngIf="!loading && results?.length === 0" class="no-results">
        <span class="sch-comobo-label">No results found</span>
      </div>

      <seech-shared-progress-bar
        *ngIf="loading"
        [progress]="30"
        [isIndeterminate]="true"
        [color]="progressBarColor"
      ></seech-shared-progress-bar>
    </div>
  </div>
</div>
