import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
  forwardRef,
  HostListener,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ViewChild,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';
import { ReactiveFormsModule, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, AbstractControl, ValidationErrors, ControlValueAccessor, Validator, Validators } from '@angular/forms';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { ValidationComponent } from '../validation/validation.component';
import { Subject, Subscription, debounceTime } from 'rxjs';
import {
  Color,
  IconDirective,
  ProgressBarComponent as SharedProgressBarComponent,
  SharedScrollEndNotifierDirective,
  SpinnerComponent,
  TextboxVariant,
} from '@seech/shared-ng';
import { InputDirective } from '../../directives';
import { ControlsConfigService } from '../../injection/controls-config.service';
import { TEXTBOX_VARIANT } from '../../constants';

@Component({
  selector: 'seech-autocomplete',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ValidationComponent,
    MdbFormsModule,
    SharedProgressBarComponent,
    InputDirective,
    IconDirective,
    SpinnerComponent,
    SharedScrollEndNotifierDirective,
  ],
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, ControlValueAccessor, Validator {
  @ViewChild('autocompleteInput') autocompleteInputRef!: ElementRef;
  @Input() displaySelectedValue = true;
  @Input() nameKey = 'name';
  @Input() valueKey = 'value';
  @Input() labelKey!: string;
  @Input() value: string | [string] | any = '';
  @Input() placeHolder = '';
  @Input() readonlyplaceholder = '';
  @Input() id: string | number = '';
  @Input() loading = false;
  @Input() label?: string;
  @Input({ required: true }) results!: any;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() clearable = true;
  @Input() showSelected = false;
  @Input() autocomplete: 'on' | 'off' = 'off';
  @Input() progressBarColor: Color = 'secondary';
  @Input() debounceTime = 300;
  @Input() threshold = 100;
  @Input() variant!: TextboxVariant;

  @Output() searchTextChanged = new EventEmitter<string>();
  @Output() valueChange = new EventEmitter<any>();
  @Output() selected = new EventEmitter<any>();
  @Output() dropdownToggle = new EventEmitter<boolean>();
  @Output() blurred = new EventEmitter<any>();
  @Output() changed = new EventEmitter<any>();
  @Output() scrollEnd = new EventEmitter<void>();

  textboxVariants = TEXTBOX_VARIANT;
  searchText!: string;
  notFound = false;
  showDropdown = false;
  selectedOption: any;
  private inputChanged = new Subject<string>();
  private clickListener: any;
  private valueChangesSub!: Subscription;

  constructor(
    private eRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: object,
    private configService: ControlsConfigService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(!this.labelKey)
      this.labelKey = this.valueKey;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.clickListener = document.addEventListener('click', this.onDocumentClick.bind(this));
    }

    if (!this.variant) {
      const config = this.configService.getConfig();
      this.variant = config?.textboxVariant ? config.textboxVariant : 'classic';
    }

    this.valueChangesSub = this.inputChanged
      .pipe(debounceTime(this.debounceTime))
      .subscribe(value => {
        this.searchTextChanged.emit(value);
        this.valueChange.emit(value);
      });
  }

  ngAfterViewInit(): void {
    this.showDropdown = false;
  }

  ngOnDestroy(): void {
    if (this.clickListener) {
      document.removeEventListener('click', this.onDocumentClick.bind(this));
    }
    this.valueChangesSub?.unsubscribe();
  }

  onDocumentClick(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showDropdown = false;
      this.cdr.detectChanges();
    }
  }

  get canClear(): boolean{
    if(!this.clearable) return false;
    if(this.readonly || this.disabled) return false;
    return this.autocompleteInputRef?.nativeElement.value ? true : false;
  }

  onInputChanged(event: any): void {
    const value = event.target.value;
    this.searchText = value;
    this.inputChanged.next(value);
    this.onChange(value);
    this.onTouched();
  }

  onOptionClicked(option: any): void {
    if (this.displaySelectedValue) {
      this.value = option?.[this.labelKey];
      this.onChange(this.value);
    }
    this.selected.emit(option);
    this.selectedOption = option;
    this.showDropdown = false;
    this.onTouched();
    this.valueChange.emit(this.value);
    this.cdr.detectChanges();
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    this.dropdownToggle.emit(this.showDropdown);
  }

  clearInput() {
    this.value = '';
    // this.showDropdown = false;
    this.onChange(this.value);
    this.onTouched();
    this.cdr.detectChanges();
  }

  blurr(event: any) {
    this.blurred.emit(event);
  }

  autocompleteChange(event: any) {
    this.changed.emit(event);
  }
  onScrollEnd(){
    this.scrollEnd.emit();
  }



   
  private onChange = (value: any) => { };
   
  private onTouched = () => { };

  writeValue(value: any): void {
    if (this.displaySelectedValue) {
      if (value !== this.value) {
        this.value = value;
        this.searchText = value;
        this.cdr.detectChanges();
      }
    } else {
      this.value = '';
      this.searchText = '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const valueIsRequired = control.hasValidator(Validators.required);
    const requiredError = { required: true };

    if (!value && valueIsRequired) {
      return requiredError;
    }

    return null;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: any) {
    const clickedInside = this.eRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.showDropdown) {
      this.toggleDropdown();
    }
    else if (clickedInside && !this.showDropdown && !this.readonly && !this.disabled) {
      this.toggleDropdown();
    }
  }
}
