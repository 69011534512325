import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { FileType as SharedFileType, SIZE } from '@seech/shared-ng';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

export type FileType = SharedFileType;

type onChangeFn = (value: FileList | null) => void;
type onTouchedFn = () => void;

@Component({
  selector: 'seech-file-upload',
  standalone: true,
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  exportAs: 'seechFileUpload',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})

export class FileUploadComponent
  implements ControlValueAccessor, OnChanges, Validator
{
  @Input() size: string = SIZE.MEDIUM;
  @Input() id: string | number | null | undefined;
  @Input() acceptMultiple = false;
  @Input() hidden = true;
  @Input() max = 1;
  @Input() capture: 'user' | 'environment' | null = 'environment';
  @Input({ required: true }) fileTypes: FileType[] = [];
  @Input({ required: true }) maxSizeInMb = 0;

  @ViewChild('fileInput') fileInput!: ElementRef;
  //Did it so that the control upload value can also be gotten even when not used in a form
  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  classes = `form-control-${this.size}`;
  @Input() disabled = false;

   
  ngOnChanges(changes: SimpleChanges): void {
    this.classes = `form-control-${this.size}`;
  }

   
  onChanged: onChangeFn = () => {};
   
  onTouched: onTouchedFn = () => {};

  private value: FileList | null = null;

  @HostListener('focusout')
  onFocusOut() {
    this.onTouched();
  }

  onFileUpload(e: any) {
    const inputElement = e.target as HTMLInputElement;
    this.value = inputElement.files;
    this.selected.emit(e);
    this.onChanged(this.value);
    this.onTouched();
  }

  get getFileTypes(): string {
    const commaSeparatedFileTypes = this.fileTypes.join(', ');
    return commaSeparatedFileTypes;
  }

  get isMediaUpload(): boolean{
    return this.fileTypes.some(x => x.trim() === 'image/*' || x.trim() === 'video/*');
  }

  writeValue(value: FileList | null): void {
    this.value = value;
  }

  registerOnChange(fn: onChangeFn): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: onTouchedFn): void {
    this.onTouched = fn;
  }

  setDisabledState?(isParentDisabled: boolean): void {
    this.disabled = isParentDisabled || this.disabled;
  }

  private getFileSizeInMb(file: File | null) {
    if (file) {
      const fileSizeInMb = file.size / (1024 * 1024);
      return fileSizeInMb;
    }
    return 0;
  }

  triggerUpload() {
    const fileInput = this.fileInput.nativeElement as HTMLInputElement;
    fileInput.click();
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    const valueIsRequired = control.hasValidator(Validators.required);
    const requiredError = { required: true };
    const maxFileSizeExceededError = { maxSizeExceeded: true };
    const value = control.value;

    if (!value && valueIsRequired) {
      return requiredError;
    }

    let totalFileSize = 0;
    if (value) {
      for (let index = 0; index < value.length; index++) {
        const fileSize = this.getFileSizeInMb(value[index]);
        totalFileSize += fileSize;
      }
    }

    if (totalFileSize > this.maxSizeInMb) {
      return maxFileSizeExceededError;
    }

    return null;
  }
}
