import { Directive, ElementRef, OnInit, Optional, Renderer2, Self } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[sch-validations]',
  standalone: true,
})
export class SeechValidationsDirective implements OnInit {
  private errorMessageElement!: HTMLElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Optional() @Self() private ngControl: NgControl
  ) { }

  ngOnInit() {
    const control = this.ngControl?.control;
    if (control) {
      // Create an error message element
      this.errorMessageElement = this.renderer.createElement('p');
      this.renderer.appendChild(this.el.nativeElement.parentElement, this.errorMessageElement);

      // Listen to status changes to update error messages dynamically
      control.statusChanges?.subscribe(() => {
        this.updateErrorMessage(control);
      });
    }
  }

  private updateErrorMessage(control: AbstractControl): void {
    if (control.invalid && control.touched) {
      const error = control.errors;
      if (error) {
        const firstErrorKey = Object.keys(error)[0]; // Get the first error
        const errorMessage = error[firstErrorKey]?.message || 'Field is invalid';
        const severity = error[firstErrorKey]?.severity || 'error';

        // Apply severity-based styling
        this.applySeverityStyling(severity);

        // Set error message
        this.renderer.setProperty(this.errorMessageElement, 'textContent', errorMessage);
      }
    } else {
      // Clear the error message if valid
      this.renderer.setProperty(this.errorMessageElement, 'textContent', '');
    }
  }

  private applySeverityStyling(severity: string): void {
    const classMap = {
      error: 'text-danger', // Red text for errors
      warning: 'text-warning', // Yellow text for warnings
      info: 'text-info' // Blue text for info
    };

    // Remove all previous classes
    this.renderer.removeClass(this.errorMessageElement, 'text-danger');
    this.renderer.removeClass(this.errorMessageElement, 'text-warning');
    this.renderer.removeClass(this.errorMessageElement, 'text-info');

    // Apply the appropriate class based on severity
    const className = classMap[severity];
    this.renderer.addClass(this.errorMessageElement, className);
  }

}
