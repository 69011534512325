import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Coordinates } from '../models';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  private scriptLoadingPromise: Promise<void> | null = null;

  constructor(private http: HttpClient) { }

  load(apiKey: string, mapEnabled: boolean) {
    if (this.scriptLoadingPromise) {
      return this.scriptLoadingPromise;
    }

    this.scriptLoadingPromise = new Promise<void>((resolve, reject) => {

      if (!apiKey) {
        console.error('Google API Key not injected');
        reject('Google API Key not injected');
        return;
      }

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;

      let libraries = 'places';
      if (mapEnabled) {
        libraries += ',drawing';
      }

      const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries}`;

      try {
        if (window['trustedTypes'] && window['defaultTrustedTypesPolicy']) {
          script.src = window['defaultTrustedTypesPolicy'].createScriptURL(
            scriptUrl
          ) as unknown as string;
        } else {
          script.src = scriptUrl;
        }
      } catch (e) {
        console.error('Error using Trusted Types policy:', e);
        script.src = scriptUrl;
      }

      script.onload = () => {
        resolve();
      };

      script.onerror = (error: any) => {
        this.scriptLoadingPromise = null;
        reject(error);
      };

      document.head.appendChild(script);
    });

    return this.scriptLoadingPromise;
  }

  // Updated method with geoCoded flag to decide if a human-readable address is needed
  getCurrentLocation(apiKey: string, geoCoded = false): Observable<Coordinates> {
    return new Observable(observer => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const coords: Coordinates = {
              location: '',
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              coords: `${position.coords.latitude}, ${position.coords.longitude}`,
            };

            if (geoCoded) {
              this.getGeoCodedAddress(apiKey, coords).subscribe(
                (geoCodedCoords) => {
                  observer.next(geoCodedCoords);
                  observer.complete();
                },
                (error) => {
                  observer.error(error);
                }
              );
            } else {
              observer.next(coords);
              observer.complete();
            }
          },
          (error) => {
           // console.error('Geolocation error, falling back to IP-based location:', error);
            this.fallbackCurrentPosition(apiKey, geoCoded).subscribe(
              (fallbackCoords) => {
                observer.next(fallbackCoords);
                observer.complete();
              },
              (fallbackError) => {
                observer.error(fallbackError);
              }
            );
          }
        );
      } else {
        // Browser doesn't support geolocation, use fallback
        this.fallbackCurrentPosition(apiKey, geoCoded).subscribe(
          (fallbackCoords) => {
            observer.next(fallbackCoords);
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
      }
    });
  }

  // Fallback method to get coordinates using an IP-based geolocation API
  private fallbackCurrentPosition(apiKey: string, geoCoded: boolean): Observable<Coordinates> {
    const geolocateURL = `https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`;
    return this.http.post<any>(geolocateURL, {}).pipe(
      switchMap((response) => {
        const coords: Coordinates = {
          location: '',
          latitude: response.location.lat,
          longitude: response.location.lng,
          coords: `${response.location.lat}, ${response.location.lng}`,
        };

        if (geoCoded) {
          return this.getGeoCodedAddress(apiKey, coords);
        } else {
          return of(coords);
        }
      }),
      catchError((err) => {
        return of({
          location: 'Unknown',
          latitude: 0,
          longitude: 0,
          coords: '0, 0',
        });
      })
    );
  }

  // New method to get the human-readable address from coordinates
  private getGeoCodedAddress(apiKey:string, coords: Coordinates): Observable<Coordinates> {
    const geocodeURL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.latitude},${coords.longitude}&key=${apiKey}`;
    return this.http.get<any>(geocodeURL).pipe(
      map((response) => {
        if (response.status === 'OK' && response.results[0]) {
          const location = response.results[0].formatted_address;
          return {
            ...coords,
            location: location,
          };
        } else {
          return coords;
        }
      }),
      catchError((err) => {
        return of(coords); // Return original coordinates if geocoding fails
      })
    );
  }
}
