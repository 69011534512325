import {
  Component,
} from '@angular/core';
import { DropdownComponent as SharedDropDown } from '@seech/shared-ng';
import { ControlsConfigService } from '../../injection/controls-config.service';

@Component({
  selector: 'seech-dropdown',
  standalone: true,
  imports: [SharedDropDown],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent extends SharedDropDown {

  constructor(private configService: ControlsConfigService) {
    super();
    const config = this.configService.getConfig();
    if(config?.dropDownShape) this.shape = config.dropDownShape;
  }
}
