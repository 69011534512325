<ng-template>
    <div class="sch-tooltip-panel bg-body" (click)="closed.emit()" [@transformTooltip]="panelAnimationState"
        (@transformTooltip.done)="onAnimationDone($event)">
        <div class="sch-tooltip-content d-flex flex-column gap-1">
            <div class="d-flex justify-content-between">
                <span class="mdb-text title">{{title}}</span>
                <span sch-icon="cancel" class="close"></span>
            </div>
            <span class="mdb-text content">{{content}}</span>
            <ng-content></ng-content>
        </div>
    </div>
</ng-template>