 
import {
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {
  IconDirective,
  SharedScrollEndNotifierDirective,
  TextboxVariant,
} from '@seech/shared-ng';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbSelectModule } from 'mdb-angular-ui-kit/select';
import { SelectOption } from '../select/select.component';
import { ControlsConfigService } from '../../injection/controls-config.service';
import { TEXTBOX_VARIANT } from '../../constants';

export interface NestedSelectOption {
  title: string;
  options: SelectOption[];
  label?: string;
  value?: any;
  hideOption?: boolean;
}
@Component({
  selector: 'seech-nested-select',
  standalone: true,
  imports: [
    CommonModule,
    MdbDropdownModule,
    MdbRippleModule,
    FormsModule,
    ReactiveFormsModule,
    MdbSelectModule,
    IconDirective,
    SharedScrollEndNotifierDirective,
  ],
  templateUrl: './nested-select.component.html',
  styleUrl: './nested-select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NestedSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NestedSelectComponent),
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NestedSelectComponent
  implements OnInit, OnChanges, ControlValueAccessor, Validator
{
  filteredOptions: NestedSelectOption[] = [];

  @Input() selectedValue: any = '';
  @Input({ required: true }) options: NestedSelectOption[] = [];
  @Input() label?: string;
  @Input() id = '';
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() showSelected = false;
  @Input() autocomplete: 'on' | 'off' = 'off';
  @Input() threshold = 100;
  @Input() placeholder = '';
  @Input() readonlyplaceholder = '';
  @Input() variant!: TextboxVariant;

  @Output() selectedValueChange = new EventEmitter<any>();
  @Output() closed = new EventEmitter<boolean>();
  @Output() opened = new EventEmitter<boolean>();
  @Output() selected = new EventEmitter<NestedSelectOption>();
  @Output() reachedEndOfList = new EventEmitter<void>();
  @Output() searchTermChange = new EventEmitter<string>();

  textboxVariants = TEXTBOX_VARIANT;
  showDropdown = false;
  @ViewChild('dropdownMenu') dropdownMenu!: ElementRef;

  private onChange = (value: any) => {};
  private onTouched = () => {};

  constructor(
    private eRef: ElementRef,
    private configService: ControlsConfigService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this.filteredOptions = [...this.options];
    }
  }

  ngOnInit(): void {
    if (!this.variant) {
      const config = this.configService.getConfig();
      this.variant = config?.textboxVariant ? config.textboxVariant : 'classic';
    }
  }

  get selectedLabel(): string {
    if (!this.selectedValue) return '';

    return this.options.reduce((label, option) => {
      if (label) return label;
      if (option.options) {
        const nestedOption = option.options.find(
          (o) => o.value === this.selectedValue
        );
        return nestedOption?.label || '';
      }
      return '';
    }, '');
  }

  selectOption(option: any) {
    this.selectedValue = option.value;
    this.onChange(option.value);
    this.onTouched();
    this.selectedValueChange.emit(option);
    this.selected.emit(option);
    this.cdr.detectChanges();
    this.toggleDropdown();
  }

  onInput(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    this.showDropdown = true;

    this.filteredOptions = this.options
      .map((option) => {
        if (option.options) {
          const filteredGroup = option.options.filter((o) =>
            o.label.toLowerCase().includes(searchTerm)
          );
          return { ...option, options: filteredGroup };
        }
        return option.label?.toLowerCase().includes(searchTerm) ? option : null;
      })
      .filter(
        (option) =>
          option && (option.label || (option.options?.length ?? 0) > 0)
      ) as NestedSelectOption[];

    this.searchTermChange.emit(searchTerm);

    // Hide the dropdown if no results are found
    if (this.filteredOptions.length === 0) {
      this.showDropdown = false;
    }
  }

  writeValue(value: any): void {
    if (value) {
      this.selectedValue = value;
    }
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    this.showDropdown === true
      ? this.opened.emit(true)
      : this.opened.emit(false);
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // return this.selectedValue ? null : { required: true };
    const value = control.value;
    const valueIsRequired = control.hasValidator(Validators.required);
    const requiredError = { required: true };
    if (!value && valueIsRequired) {
      return requiredError;
    }
    return null;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this.eRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.showDropdown) {
      this.toggleDropdown();
      this.closed.emit(true);
      this.opened.emit(false);
    }
  }

  onScrollEnd() {
    this.reachedEndOfList.emit();
  }
}
