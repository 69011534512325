import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayConfig,
} from '@angular/cdk/overlay';
import { _getEventTarget } from '@angular/cdk/platform';
import { takeUntil } from 'rxjs/operators';
import {
  CDK_MENU,
  CdkMenuTriggerBase,
  MENU_TRIGGER,
  PARENT_OR_NEW_MENU_STACK_PROVIDER,
} from '@angular/cdk/menu';

export type MenuPositionX = 'start' | 'end' | 'center';

@Directive({
  selector: '[sch-menu-trigger-for]',
  exportAs: 'schMenuTriggerFor',
  standalone: true,
  host: {
    class: 'sch-menu-trigger',
  },
  providers: [
    { provide: MENU_TRIGGER, useExisting: MenuTriggerDirective },
    PARENT_OR_NEW_MENU_STACK_PROVIDER,
  ],
})
export class MenuTriggerDirective
  extends CdkMenuTriggerBase
  implements OnDestroy
{
  private readonly elRef: ElementRef<HTMLElement> = inject(ElementRef);
  @Input() anchorTo: ElementRef<HTMLElement> | null = null;

  private readonly overlay = inject(Overlay);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly parentMenu = inject(CDK_MENU, { optional: true });

  @Input() horizontalPosition: MenuPositionX = 'end';
  @Input() backdropClass = 'bg-transparent';

  @Input('sch-menu-trigger-for')
  set _menuTemplateRef(value: TemplateRef<any>) {
    this.menuTemplateRef = value;
  }

  @Output() menuOpened = this.opened; // same as @Output() menuOpened = super.opened
  @Output() menuClosed = this.closed; // same as @Output() menuClosed = super.closed

  @HostListener('focusin') onFocusIn() {
    this.setHasFocus(true);
  }

  @HostListener('focusout') onFocusOut() {
    this.setHasFocus(false);
  }

  @HostListener('click') onClick() {
    this.handleClick();
  }

  constructor() {
    super();
    this.registerCloseHandler();
  }

  toggle() {
    this.isOpen() ? this.close() : this.open();
  }

  open() {
    if (!this.isOpen() && this.menuTemplateRef != null) {
      this.opened.next();
      this.overlayRef =
        this.overlayRef || this.overlay.create(this.getOverlayConfig());
      const overlayConfig = this.overlayRef.getConfig();
      const positionStrategy =
        overlayConfig.positionStrategy as FlexibleConnectedPositionStrategy;
      this.setMenuPosition(positionStrategy);
      this.overlayRef.attach(this.getMenuContentPortal());
      this.cdRef.markForCheck();
      this.subscribeToOutsideClicks();
    }
  }

  close() {
    if (this.isOpen()) {
      this.closed.next();
      this.overlayRef!.detach();
      this.cdRef.markForCheck();
    }
  }

  handleClick() {
    this.toggle();
    this.childMenu?.focusFirstItem('mouse');
  }

  setHasFocus(hasFocus: boolean) {
    if (!this.parentMenu) {
      this.menuStack.setHasFocus(hasFocus);
    }
  }

  private getOverlayConfig() {
    return new OverlayConfig({
      positionStrategy: this.getOverlayPositionStrategy(),
      scrollStrategy: this.menuScrollStrategy(),
      direction: 'ltr',
      hasBackdrop: true,
      backdropClass: this.backdropClass,
    });
  }

  private getOverlayPositionStrategy(): FlexibleConnectedPositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(this.anchorTo ?? this.elRef)
      .withLockedPosition()
      .withGrowAfterOpen();
  }

  private subscribeToOutsideClicks() {
    if (this.overlayRef) {
      this.overlayRef
        .outsidePointerEvents()
        .pipe(takeUntil(this.stopOutsideClicksListener))
        .subscribe((event) => {
          const target = _getEventTarget(event) as Element;
          const element = this.elRef.nativeElement;

          if (target !== element && !element.contains(target)) {
            if (!this.isElementInsideMenuStack(target)) {
              this.menuStack.closeAll();
            }
          }
        });
    }
  }

  private registerCloseHandler() {
    if (!this.parentMenu) {
      this.menuStack.closed
        .pipe(takeUntil(this.destroyed))
        .subscribe(({ item }) => {
          if (item === this.childMenu) {
            this.close();
          }
        });
    }
  }

  private setMenuPosition(positionStrategy: FlexibleConnectedPositionStrategy) {
    const offsetY = 2;
    positionStrategy.withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetX: 0,
        offsetY: offsetY, // Adjusts the vertical gap between the origin and overlay
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetX: 0,
        offsetY: -offsetY, // Positions the overlay above if there's no space below
      },
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
        offsetX: 0,
        offsetY: offsetY, // Positions the overlay aligned to the right
      },
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom',
        offsetX: 0,
        offsetY: -offsetY, // Positions above with alignment to the right
      },
    ]);
  }
}
