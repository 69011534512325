<div class="sch-input">
  <label
    *ngIf="label && variant === textboxVariants.CLASSIC"
    class="sch-input-label {{ variant }} {{ textClass }}"
    for="{{ id }}"
    [attr.readonly]="readonly"
    [attr.disabled]="disabled"
  >
    {{ label }}
  </label>

  <div class="position-relative">
    <input
      [id]="id"
      [type]="type"
      (isValid)="handleValidation($event)"
      [(ngModel)]="value"
      (input)="onInput()"
      [autocomplete]="autocomplete"
      class="sch-input-field {{ variant }} {{ textClass }} {{ borderClass }}"
      [class.has-value]="value"
      [class.has-placeholder]="placeholder"
      [class.has-icon]="hasIcon"
      [ngClass]="{'align-icon-start': alignIcon === 'left'}"
      [placeholder]="readonly ? readonlyplaceholder : placeholder"
      [readonly]="readonly"
      [disabled]="disabled"
      (blur)="onBlur()"
      [attr.maxlength]="maxLength > 0 ? maxLength : null"
    />

    <label
      *ngIf="label && variant !== textboxVariants.CLASSIC"
      class="sch-input-label {{ variant }} {{ textClass }}"
      for="{{ id }}"
      [attr.readonly]="readonly"
      [attr.disabled]="disabled"
    >
      {{ label }}
    </label>

    <ng-container *ngIf="hasIcon">
      <i
        (click)="readonly || disabled ? null : actionIconClick.emit()"
        [sch-icon]="icon!"
        class="sch-input-icon {{ textClass }}"
      ></i>
    </ng-container>
  </div>
</div>
