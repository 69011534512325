import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Icon } from '../constants';

@Directive({
  selector: '[sch-icon]',
  standalone: true,
})
export class IconDirective implements OnChanges {
  @Input('sch-icon') name!: Icon;
  @HostBinding('class') hostElementClass!: string;
  @HostBinding('attr.data-icon') dataIcon!: string;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.hostElementClass = this.getIconClass();
    this.dataIcon = this.getIconAttribute();
  }

  private getIconClass() {
    return `si-${this.name}`;
  }

  private getIconAttribute() {
    if (this.name.endsWith('-fill')) {
      return 'fill';
    }
    return 'outline';
  }
}
