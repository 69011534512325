<seech-input
    [type]="type"
    [label]="label"
    [id]="id"
    (valid)="handleValidation($event)"
    [(ngModel)]="value"
    (input)="onInput()"
    [autocomplete]="autocomplete"
    [placeholder]="placeholder"
    [variant]="variant"
    [alignIcon]="alignIcon"
    [disabled]="disabled"
    [icon]="passwordVisible ? 'visibility-off' : 'visibility'"
    (actionIconClick)="handleVisibility()"
    [maxLength]="maxLength">
</seech-input>