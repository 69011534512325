import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { Utilities } from '@seech/shared-logic-ng';
import { ChipComponent } from '@seech/shared-ng';

export interface Chip {
  description: string;
  value: string;
  color: string;
  image?: string;
  id?: string | number;
}

export interface ChipConfig {
  keys?: {
    description: string;
    value: string;
    color: string;
    image?: string;
    id?: string | number;
  } ;
  override?: ChipOverride;
}

export interface ChipOverride {
  exclude?: string[];
  replacement?: string;
}

@Component({
  selector: 'seech-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    ChipComponent
  ],
})

export class ChipsComponent implements OnInit, OnChanges {
  @Input({ required: true }) values!: Chip[] | any[];
  @Input() fill: 'solid' | 'outline' = 'solid';
  @Input() inlined = true;
  @Input() variant: 'single-colored' | 'multi-colored' = 'single-colored';
  @Input() color = 'primary';
  // @Input() override: ChipOverride = {};
  @Input() id: number | string = '';
  @Input() config: ChipConfig = {} as any;
  @Input() createAvatar!: boolean;
  @Input() cancelable = false;

  @Output() valuesChange = new EventEmitter<any[]>();

  private colorOptions = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark'];

  processedValues: any[] = [];



  ngOnInit(): void {
    this.processValues();
  }



  ngOnChanges(changes: SimpleChanges): void {
    if (changes['values']) {
      this.values = Utilities.deepClone(changes['values'].currentValue);
      // this.values = JSON.parse(JSON.stringify(changes['values'].currentValue));
      this.processValues();
    }
  }

  processValues(): void {
    const {
      description = 'description',
      value = 'value',
      color = 'color',
      image = 'image',
      id = 'id'
    } = this.config?.keys || {};

    this.processedValues = this.values?.map((item: { [x: string]: any; },) => ({
      description: item[description],
      value: item[value],
      color: item[color] || this.color,
      image: image ? item[image] : null,
      id: id ? item[id] : null
    }));

  }

  applyColors(chip?: Chip) {
    let chipColor;

    // Check if the variant is 'multi-colored'
    if (this.variant === 'multi-colored') {
      // Select a random color from colorOptions
      chipColor = this.colorOptions[Math.floor(Math.random() * this.colorOptions.length)];
    } else {
      // Use the default color
      chipColor = chip?.color || this.color;
    }

    // Apply the override (works for both multi-colored and single color)
    chipColor = this.overrideColor(chipColor);
    return chipColor;
  }


  overrideColor(color: string): string {
    // Check if override is defined and then check exclude and replacement
    if (this.config.override && this.config.override.exclude && this.config.override.replacement) {
      if (this.config.override.exclude.includes(color)) {
        return this.config.override.replacement;
      }
    }
    return color;
  }


  removeChip(chipId: string): void {
    this.processedValues = this.processedValues?.filter((item) => item.id !== chipId);
    this.valuesChange.emit(this.processedValues);
  }
}
