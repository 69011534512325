import {
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  Overlay,
  OverlayConfig,
  OverlayRef,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnDestroy,
  output,
  ViewContainerRef,
} from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { DialogTooltipComponent } from '../components';

@Directive({
  selector: `[sch-tooltip-trigger-for]`,
  host: {
    class: 'sch-tooltip-trigger',
  },
  exportAs: 'schTooltipTrigger',
  standalone: true,
})
export class TooltipTriggerDirective implements OnDestroy {
  private overlay = inject(Overlay);
  private element = inject<ElementRef<HTMLElement>>(ElementRef);
  private viewContainerRef = inject(ViewContainerRef);

  private scrollStrategy = this.overlay.scrollStrategies.reposition();
  private cdRef = inject(ChangeDetectorRef);

  private portal?: TemplatePortal;
  private overlayRef: OverlayRef | null = null;
  private _tooltipOpen = false;
  private closingActionsSubscription = Subscription.EMPTY;
  private hoverSubscription = Subscription.EMPTY;
  private tooltipCloseSubscription = Subscription.EMPTY;
  private backDropClick = false;

  @Input('sch-tooltip-trigger-for')
  get tooltip(): DialogTooltipComponent | null {
    return this._tooltip;
  }
  set tooltip(tooltip: DialogTooltipComponent | null) {
    if (tooltip === this._tooltip) {
      return;
    }

    this._tooltip = tooltip;
    this.tooltipCloseSubscription.unsubscribe();

    if (tooltip) {
      this.tooltipCloseSubscription = tooltip.closed.subscribe(() => {
        this.destroyTooltip();
      });
    }
  }
  private _tooltip: DialogTooltipComponent | null = null;

  //Angular 17 version of @Output
  tooltipOpened = output<void>();
  tooltipClosed = output<void>();

  @HostListener('pointerenter')
  onMouseEnter() {
    if (this.backDropClick) {
      this.backDropClick = false;
      return;
    }
    if (!this._tooltipOpen) {
      this.openTooltip();
      this.setIsTooltipOpen(true);
    }
  }

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }

    this.tooltipCloseSubscription.unsubscribe();
    this.closingActionsSubscription.unsubscribe();
    this.hoverSubscription.unsubscribe();
  }

  toggleTooltip(): void {
    return this._tooltipOpen ? this.closeTooltip() : this.openTooltip();
  }

  openTooltip(): void {
    const tooltip = this.tooltip;

    if (this._tooltipOpen || !tooltip) {
      return;
    }

    const overlayRef = this.createOverlay();
    const overlayConfig = overlayRef.getConfig();
    const positionStrategy =
      overlayConfig.positionStrategy as FlexibleConnectedPositionStrategy;

    this.setPosition(tooltip, positionStrategy);
    overlayConfig.hasBackdrop = true;
    overlayRef.attach(this.getPortal(tooltip));
    this.setIsTooltipOpen(true);
    tooltip.startAnimation();

    this.closingActionsSubscription = this.tooltipClosingActions().subscribe(
      () => this.closeTooltip()
    );
  }

  closeTooltip(): void {
    this.tooltip?.closed.emit();
  }

  private destroyTooltip() {
    if (!this.overlayRef || !this._tooltipOpen) {
      return;
    }

    const tooltip = this.tooltip;
    this.closingActionsSubscription.unsubscribe();
    this.overlayRef.detach();

    if (tooltip !== null) {
      tooltip.resetAnimation();
      this.setIsTooltipOpen(false);
    }
  }

  private setIsTooltipOpen(isOpen: boolean): void {
    if (isOpen !== this._tooltipOpen) {
      this._tooltipOpen = isOpen;
      this._tooltipOpen ? this.tooltipOpened.emit() : this.tooltipClosed.emit();

      this.cdRef.markForCheck();
    }
  }

  private createOverlay(): OverlayRef {
    if (!this.overlayRef) {
      const config = this.getOverlayConfig();
      this.overlayRef = this.overlay.create(config);
      this.overlayRef.keydownEvents().subscribe();

      this.overlayRef.backdropClick().subscribe(() => {
        this.backDropClick = true;
      });
    }
    return this.overlayRef;
  }

  private getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.element)
        .withLockedPosition()
        .withGrowAfterOpen()
        .withTransformOriginOn('.sch-tooltip-panel, .sch-tooltip-panel'),
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.scrollStrategy,
    });
  }

  private setPosition(
    tooltip: DialogTooltipComponent,
    positionStrategy: FlexibleConnectedPositionStrategy
  ) {
    const [originX, originFallbackX]: HorizontalConnectionPos[] =
      tooltip.horizontalPosition === 'start'
        ? ['end', 'start']
        : ['start', 'end'];

    const [overlayY, overlayFallbackY]: VerticalConnectionPos[] =
      tooltip.verticalPosition === 'top'
        ? ['bottom', 'top']
        : ['top', 'bottom'];

    let [originY, originFallbackY] = [overlayY, overlayFallbackY];
    const [overlayX, overlayFallbackX] = [originX, originFallbackX];
    const offsetY = tooltip.verticalPosition === 'top' ? -10 : 10;

    if (!tooltip.overlapTrigger) {
      originY = overlayY === 'top' ? 'bottom' : 'top';
      originFallbackY = overlayFallbackY === 'top' ? 'bottom' : 'top';
    }

    positionStrategy.withPositions([
      { originX, originY, overlayX, overlayY, offsetY: offsetY },
      {
        originX: originFallbackX,
        originY,
        overlayX: overlayFallbackX,
        overlayY,
        offsetY,
      },
      {
        originX,
        originY: originFallbackY,
        overlayX,
        overlayY: overlayFallbackY,
        offsetY: -offsetY,
      },
      {
        originX: originFallbackX,
        originY: originFallbackY,
        overlayX: overlayFallbackX,
        overlayY: overlayFallbackY,
        offsetY: offsetY,
      },
    ]);
  }

  private tooltipClosingActions() {
    const backdrop = this.overlayRef!.backdropClick();
    const detachments = this.overlayRef!.detachments();
    return merge(backdrop, detachments);
  }

  private getPortal(tooltip: DialogTooltipComponent): TemplatePortal {
    if (!this.portal || this.portal.templateRef !== tooltip.templateRef) {
      this.portal = new TemplatePortal(
        tooltip.templateRef,
        this.viewContainerRef
      );
    }
    return this.portal;
  }
}
