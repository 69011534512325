import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormsModule, NG_VALIDATORS, ReactiveFormsModule, ValidationErrors, Validator, Validators } from '@angular/forms';

import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CharacterLimitDirective } from '../../directives';
import { TextFieldModule } from '@angular/cdk/text-field';
import { TextboxVariant } from '@seech/shared-ng';
import { ControlsConfigService } from '../../injection/controls-config.service';
import { TEXTBOX_VARIANT } from '../../constants';

@Component({
  selector: 'seech-textarea',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MdbFormsModule,
    CharacterLimitDirective,
    TextFieldModule,
  ],
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() label?: string;
  @Input() id = '';
  @Input() value: any = '';
  @Input() autocomplete?: 'on' | 'off' = 'off';
  @Input() placeholder = '';
  @Input() readonlyplaceholder = '';
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() variant!: TextboxVariant;

  // Inputs for the CharacterLimitDirective
  @Input() maxLength!: number | null;  // Default maxLength can be set here
  @Input() threshold = 80;                  // Default threshold percentage
  @Input() embedCounter = true;             // Embed counter by default
  @Input() allowExceed = true;             // Disallow exceeding the maxLength
  @Input() limitCharacters = true;

  @Output() characterLimitValid = new EventEmitter<boolean>();
  @Output() characterLimitReached = new EventEmitter<void>();

  @Output() valueChange: any = new EventEmitter<any>();
  @Output() valid: any = new EventEmitter<boolean>();
  @Output() actionIconClick: any = new EventEmitter<void>();
  @Input() minRows = 3;
  @Input() maxRows = 3;

  textboxVariants = TEXTBOX_VARIANT;
  validInput = true;
  private characterLimitExceeded = false; // Track if character limit is exceeded

  constructor(private configService: ControlsConfigService) { }

  ngOnInit(): void {
    if (!this.variant) {
      const config = this.configService.getConfig();
      this.variant = config?.textboxVariant ? config.textboxVariant : 'classic';
    }
  }

  handleValidation(event: any): void {
    this.validInput = event;
    this.valid.emit(this.validInput);
  }

  onInput(): void {
    this.valueChange.emit(this.value);
    this.onChange(this.value); // This will notify the parent form of the value change
    this.onTouched(); // This will notify the parent form that the input field has been touched
  }

  // Handler for character limit reached event from the directive
  onCharacterLimitReached(): void {
    this.characterLimitReached.emit();
  }

  // Handler for character limit exceeded event from the directive
  onCharacterLimitExceeded(exceeded: boolean): void {
    this.characterLimitExceeded = exceeded;
    this.emitValidationStatus();
    this.characterLimitValid.emit(!exceeded);
  }

  private emitValidationStatus(): void {
    const isValid = !this.characterLimitExceeded;
    this.valid.emit(isValid);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onChange = (value: any) => {
    // console.log("onChange", value);
  };
  private onTouched = () => {
    // console.log("onTouched");
  };

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const valueIsRequired = control.hasValidator(Validators.required);
    const requiredError = { required: true };

    // Check if the value is required and missing
    if (!value && valueIsRequired) {
      return requiredError;
    }

    // Check if the character limit is exceeded
    if (this.characterLimitExceeded) {
      return { characterLimitExceeded: true };
    }

    return null;
  }
}
