import { Injectable } from '@angular/core';
import { ControlsConfigService } from '../injection/controls-config.service';
import { GeolocationService } from '@seech/shared-logic-ng';

@Injectable({
  providedIn: 'root',
})
export class LocationService {

  apiKey: string;

  constructor(
    private controlsConfigService: ControlsConfigService,
    private geolocationService: GeolocationService
  ) {
    this.apiKey = this.controlsConfigService.getConfig().googleAPIKey || '';
  }

  load(mapEnabled: boolean): Promise<void> {
    return this.geolocationService.load(this.apiKey, mapEnabled);
  }

  getCurrentLocation(geoCoded = false) {
    return this.geolocationService.getCurrentLocation(this.apiKey, geoCoded);
  }
}
