<div
  class="chips-container"
  [ngClass]="{
    'inline-chips': inlined,
    'block-chips': !inlined
  }"
  id="{{ id }}"
>
  <seech-shared-chip
    *ngFor="let chip of processedValues"
    [description]="chip.description"
    [color]="applyColors(chip)"
    [image]="chip.image"
    [fill]="fill"
    (cancel)="removeChip(chip.id)"
    [createAvatar]="createAvatar"
    [id]="id"
    [cancelable]="cancelable"
  ></seech-shared-chip>
</div>
