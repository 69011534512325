import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import {
  DropdownDirective,
  InputDirective,
  ButtonDirective,
  SeechValidationsDirective,
} from './directives';
import {
  ValidationComponent,
  AutocompleteComponent,
  ComboSelectComponent,
  DateComponent,
  DateTimeComponent,
  TimeComponent,
  SwitchComponent,
  PhoneComponent,
  FileUploadComponent,
  SelectComponent,
  InputComponent,
  DropdownComponent,
  ComboButtonComponent,
  CheckboxComponent,
  CurrencyComponent,
  LocationComponent,
  MoneyComponent,
  TextareaComponent,
  PasswordComponent,
  MapComponent,
  StarRatingComponent,
} from './components';
import { ControlsConfig } from './injection/controls-config.model';
import { ControlsConfigService } from './injection/controls-config.service';
import { HttpClientModule } from '@angular/common/http';

const COMPONENTS = [
  ValidationComponent,
  AutocompleteComponent,
  ComboSelectComponent,
  FileUploadComponent,
  PhoneComponent,
  SwitchComponent,
  TimeComponent,
  DateTimeComponent,
  DateComponent,
  ButtonDirective,
  DropdownDirective,
  InputDirective,
  SeechValidationsDirective,
  SelectComponent,
  InputComponent,
  DropdownComponent,
  ComboButtonComponent,
  CheckboxComponent,
  CurrencyComponent,
  LocationComponent,
  TextareaComponent,
  PasswordComponent,
  MoneyComponent,
  MapComponent,
  StarRatingComponent,
];

@NgModule({
  imports: [...COMPONENTS, HttpClientModule],
  exports: [...COMPONENTS],
  declarations: [],
})
export class ControlsNgModule {
  static forRoot(
    config: ControlsConfig
  ): ModuleWithProviders<ControlsNgModule> {
    return {
      ngModule: ControlsNgModule,
      providers: [
        ControlsConfigService,
        {
          provide: APP_INITIALIZER,
          useFactory: (controlsConfigService: ControlsConfigService) => {
            return () => {
              controlsConfigService.setConfig(config);
            };
          },
          deps: [ControlsConfigService],
          multi: true,
        },
      ],
    };
  }
}
