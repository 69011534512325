import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { NgTemplateOutlet } from '@angular/common';
import { StepComponent } from './step.component';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';

export interface StepChangedEvent {
  index: number;
  step: StepComponent;
}
@Component({
  selector: 'seech-stepper',
  standalone: true,
  imports: [CommonModule, NgTemplateOutlet, CdkStepperModule, MdbRippleModule],
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
})
export class StepperComponent extends CdkStepper implements OnInit {
  @Input() headerClass = '';
  @Output() stepChanged: EventEmitter<StepChangedEvent> =
    new EventEmitter<StepChangedEvent>();
  @Input() id: string | number | null | undefined = null;
  @HostBinding('attr.id') elementId = this.id;
  @Input() showHeader = true;
  @Input() headerBackgroundColor = '#F9FAFB';

  selectStep(index: number): void {
    this.selectedIndex = index;
    this.stepChanged.emit({
      index: index,
      step: this._steps[index] as StepComponent,
    });
  }

  ngOnInit(): void {
    this.elementId = this.id ?? '';
  }

  isActive(index: number) {
    return this.selectedIndex === index;
  }

  computeId(prefix: string): string | null {
    return '' + this.id ? `${prefix}-${this.id}` : null;
  }
}