import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  AfterContentInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  IconDirective,
  PHONE_NUMBER_TOKEN,
  PhoneValidatorInterface,
  SIZE,
  SharedScrollEndNotifierDirective,
  SpinnerComponent,
} from '@seech/shared-ng';
import { PhoneNgValidator } from './phone.ng-validator';
import { OverlayModule } from '@angular/cdk/overlay';


export interface CountriesPhoneCodeInterface {
  countryName: string;
  countryCode: string;
  phoneCode: string;
}

export interface PhoneValueInterface {
  countryCode?: string;
  phoneNumber?: string;
  phoneCode?: string;
  extension?: string;
  e164?: string;
}

export interface SelectScrollEvent {
  start: number;
  end: number;
}

export interface SelectSearchEvent {
  term: string;
  items: any[];
}

@Component({
  selector: 'seech-phone',
  standalone: true,
  imports: [FormsModule, CommonModule, OverlayModule, IconDirective, SpinnerComponent, SharedScrollEndNotifierDirective],
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneComponent),
      multi: true,
    },
  ],
})
export class PhoneComponent
  extends PhoneNgValidator
  implements OnChanges, AfterContentInit, ControlValueAccessor
{
  @ViewChild('phoneGroup') phoneGroupRef!: ElementRef;
  controlValue: PhoneValueInterface | any = {} as PhoneValueInterface;
  @Input() size = SIZE.MEDIUM;
  @Input() placeholder = '(000) 000-000';
  @Input() readonlyplaceholder = '';
  @Input() enableExtension = false;
  @Input() extensionLength = 5;
  @Input() required = true;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() phoneNumberLength = 15;
  @Input() phoneLabel = '';
  @Input() extensionLabel = '';
  @Input() threshold = 10;
  @Input() id: string | number | null | undefined;

  //extensionNumber was two way data binded to extension's input element in phone.component.html
  extensionNumber: string | any = '';
  hostElementClasses = this.getHostElementClasses();
  countryCode?: string | any = '';
  searchKey?: string | any = '';

  //formattedPhoneNumber was two way data binded to the phone's input element in phone.component.html
  formattedPhoneNumber!: string;
  //phoneNumber is the phoneNumber property value for this control's value object of type PhoneValueInterface
  phoneNumber?: string | any = '';

  @Input() paginatedItems: CountriesPhoneCodeInterface[] = [];
  @Input() loading = false;
  touched = false;
  @Output() selectScroll = new EventEmitter<SelectScrollEvent>();
  @Output() selectScrollToEnd = new EventEmitter<void>();
  @Output() selectSearch = new EventEmitter<string>();
  @Output() selected = new EventEmitter<CountriesPhoneCodeInterface>();

  dropdownOpen = false
  inputFocused = false

  onScroll(e: SelectScrollEvent) {
    this.selectScroll.emit(e);
  }
  onScrollToEnd() {
    this.selectScrollToEnd.emit();
  }

  constructor(
    @Inject(PHONE_NUMBER_TOKEN)
    private phoneValidator: PhoneValidatorInterface,
  ) {
    super(phoneValidator);
  }

  ngAfterContentInit(): void {
    this.onChange(this.controlValue);
  }

  get phoneGroupRefWidth(): string{
    return this.phoneGroupRef.nativeElement.clientWidth + 'px';
  }

  computeId(prefix: string): string | null {
    return '' + this.id ? `${prefix}-${this.id}` : null;
  }

  onSearch() {
    this.selectSearch.emit(this.searchKey);
  }

  onCountryCodeSelect(country: CountriesPhoneCodeInterface) {
    if(country.countryCode !== this.controlValue?.countryCode){
      this.controlValue.countryCode = country.countryCode;
      this.controlValue.phoneCode = country.phoneCode;
      this.controlValue.phoneNumber = '';
      this.formattedPhoneNumber = '';
      this.phoneNumber = '';
      this.dropdownOpen = false;
      this.emitChanges(this.controlValue);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.hostElementClasses = this.getHostElementClasses();
  }

  emitChanges(value: PhoneValueInterface) {
    if (value.phoneNumber) {
      const e164PhoneNumber = this.phoneValidator.formatToE164(
        value.phoneNumber,
        this.controlValue.countryCode
      );
      const valueWithE164 = {
        ...value,
        e164: e164PhoneNumber,
      };
      this.onChange(valueWithE164);
    } else {
      this.onChange(value);
    }
    this.onTouched();
  }

  private updatePhoneNumber() {
    const userHaveTypedAtLeastANumber = this.formattedPhoneNumber !== null;
    if (userHaveTypedAtLeastANumber) {
      const unFormattedNumber = this.getNumbersOnlyFrom(
        this.formattedPhoneNumber
      );
      this.phoneNumber = unFormattedNumber || undefined;
    }
  }

  private updateExtensionNumber(extensionNumber: string) {
    const extensionPrefix = 'x';
    /**
     * Extension input element in the UI gets updated when a value get assigned to this.extensionNumber as
     * this.extensionNumber is two way data binded to extension input element in the UI
     */
    this.extensionNumber = extensionNumber
      ? `${extensionPrefix}${extensionNumber}`
      : '';
  }

  private updateControlValueWithExtensionNumber() {
    const numbersOnlyExtension = this.getNumbersOnlyFrom(this.extensionNumber);
    this.updateExtensionNumber(numbersOnlyExtension);
    this.controlValue = {
      ...this.controlValue,
      extension: numbersOnlyExtension === '' ? undefined : numbersOnlyExtension,
    };
  }

  onExtensionNumberChanged() {
    this.updateControlValueWithExtensionNumber();
    this.emitChanges(this.controlValue);
  }

  //If formatted number is '090 605 10' index of spaces in formatted number will be 3 and 7
  indexOfSpacesInPreviouslyFormattedNumber: number[] = [];
  onPhoneNumberChanged(e: KeyboardEvent | Event) {
    this.updatePhoneNumber();
    /**
     * Phone input element in the UI gets updated when a value get assigned to this.formattedPhoneNumber as
     * this.formattedPhoneNumber is two way data binded to phone input element in the UI
     */
    const phoneInputFieldIsNotEmpty = !!this.phoneNumber;
    if (phoneInputFieldIsNotEmpty) {
      this.formattedPhoneNumber = this.phoneValidator.getFormattedPhoneNumber(
        this.controlValue.countryCode,
        this.phoneNumber
      );
    }

    if (!phoneInputFieldIsNotEmpty) {
      this.formattedPhoneNumber = '';
    }

    this.controlValue = {
      ...this.controlValue,
      phoneNumber: this.formattedPhoneNumber,
    };

    this.emitChanges(this.controlValue);
    e.type === 'change' && this.selected.emit(this.controlValue);
  }

  private getNumbersOnlyFrom(value: string) {
    return value.replace(/[^0-9]/g, '');
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  private onChange = (value: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  //The value in writeValue parameter is the value passed to the form control of this component
  //on initialization e.g new FormControl({countryCode: 'US'; phoneCode: '+1'})
  writeValue(value: PhoneValueInterface | string | null): void {
    if(typeof value === 'string'){
      const phoneNumber = this.phoneValidator.formatFromE164(value);
      if(phoneNumber){
        this.controlValue = <PhoneValueInterface>{
          countryCode: phoneNumber.country,
          phoneCode: `+${phoneNumber.countryCallingCode}`,
          phoneNumber: phoneNumber.nationalNumber,
          extension: phoneNumber?.ext,
          e164: phoneNumber.number,
        }
      }
    }
    else{
      this.controlValue = value ? value : <PhoneValueInterface>{};
    }

    this.countryCode = this.controlValue?.countryCode;
    //If a default extension number was provided to this component's form control
    if (this.controlValue?.extension) {
      this.updateExtensionNumber(this.controlValue?.extension);
    }
    //If a default phone number was provided to this component's form control
    if (this.controlValue?.countryCode && this.controlValue.phoneNumber) {
      this.formattedPhoneNumber = this.phoneValidator.getFormattedPhoneNumber(
        this.controlValue?.countryCode,
        this.controlValue.phoneNumber
      );
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  getHostElementClasses(): string {
    return `input-group-${this.size}`;
  }

  setDisabledState?(isParentDisabled: boolean): void {
    this.disabled = isParentDisabled || this.disabled;
  }

  @HostListener('focusout')
  onFocusOut() {
    this.onTouched();
  }
}
