<seech-input
  [type]="passcodeVisible ? 'tel' : 'password'"  
  inputmode="numeric"  
  pattern="\d*"  
  [label]="label"
  [id]="id"
  [value]="value"
  (input)="onInput($event)"
  (keydown)="onKeydown($event)" 
  [placeholder]="placeholder"
  [variant]="variant"
  [alignIcon]="alignIcon"
  [disabled]="disabled"
  [icon]="passcodeVisible ? 'visibility-off' : 'visibility'"
  (actionIconClick)="handleVisibility()"
  [maxLength]="maxLength"
  (blurred)="onBlur()"
>
</seech-input>
