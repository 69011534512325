import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { IconsNgModule } from '@seech/icons-ng';
import { ModalService, UxNgModule } from '@seech/ux-ng';
import { ControlsNgModule } from '@seech/controls-ng';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from './settings.service';
import { MdbRangeModule } from 'mdb-angular-ui-kit/range';
import {
  DefaultControlsSettings,
  DefaultIconSettings,
  DefaultMediaSettings,
} from './settings';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  selector: 'app-settings',
  imports: [
    CommonModule,
    UxNgModule,
    IconsNgModule,
    ControlsNgModule,
    MdbRangeModule,
    FormsModule
  ],
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  controlsCollapsed = true;
  generalCollapsed = true;
  mediaCollapsed = true;
  iconsCollapsed = true;

  allSettings: any;

  minIconWeight = 100;
  maxIconWeight = 700;

  private subscription = new Subscription();

  constructor(
    private settingsService: SettingsService,
    private router: Router,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef
  ) {
    const currentRoute = this.router.url;

    if (currentRoute) {
      switch (true) {
        case currentRoute.includes('controls'):
          this.controlsCollapsed = false;
          break;
        case currentRoute.includes('media'):
          this.mediaCollapsed = false;
          break;
        case currentRoute.includes('icons'):
          this.iconsCollapsed = false;
          break;
        default:
          break;
      }
    }
  }

  ngOnInit(): void {
    this.subscription.add(
      this.settingsService.getSettings().subscribe((settings) => {
        this.allSettings = settings;
      })
    );
  }

  saveSettings() {
    this.settingsService.updateSettings(this.allSettings);
  }

  generalReset() {
    this.allSettings.general.forEach((set) => {
      for (const key in set) {
        switch (key) {
          case 'collapseAllValue':
            set[key] = false;
            break;
        }
      }
    });

    this.resetControlsSettings();
    this.resetIconsSettings();
    this.resetMediaSettings();
  }

  resetIconsSettings() {
    const iconDefaults = { ...DefaultIconSettings };

    this.resetSettings(this.allSettings.icons, iconDefaults);
  }

  resetMediaSettings() {
    const audioDefaults = { ...DefaultMediaSettings.audio };
    const videoDefaults = { ...DefaultMediaSettings.video };

    this.resetSettings(this.allSettings.media.audio, audioDefaults);
    this.resetSettings(this.allSettings.media.video, videoDefaults);
  }

  resetControlsSettings() {
    const controlsDefaults = { ...DefaultControlsSettings };

    this.resetSettings(this.allSettings.controls, controlsDefaults);
  }

  resetSettings = (settingsType, defaults) => {
    settingsType.forEach((set) => {
      for (const key in set) {
        if (defaults[key] !== undefined) {
          set[key] = defaults[key];
        }
      }
      this.saveSettings();
      this.cdr.markForCheck();
    });
  };

  closeModal() {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
