import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  booleanAttribute,
  AfterRenderRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { Subject } from 'rxjs';
import { tooltipAnimations } from './animation';
import { IconDirective } from '@seech/shared-ng';
import { CommonModule } from '@angular/common';

export type TooltipPositionX = 'start' | 'end';
export type TooltipPositionY = 'top' | 'bottom';

export interface TooltipDefaultOptions {
  xPosition: TooltipPositionX;
  yPosition: TooltipPositionY;
  overlapTrigger: boolean;
  backdropClass: string;
}

export const TOOLTIP_DEFAULT_OPTIONS: TooltipDefaultOptions = {
  overlapTrigger: false,
  xPosition: 'end',
  yPosition: 'bottom',
  backdropClass: 'cdk-overlay-transparent-backdrop',
};

@Component({
  selector: 'seech-dialog-tooltip',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'seechDialogTooltip',
  animations: [
    tooltipAnimations.transformTooltip,
    tooltipAnimations.fadeInItems,
  ],
  standalone: true,
  imports: [CommonModule, IconDirective],
  templateUrl: './dialog-tooltip.component.html',
  styleUrls: ['./dialog-tooltip.component.scss'],
})
export class DialogTooltipComponent implements OnDestroy {
  private _xPosition: TooltipPositionX;
  private _yPosition: TooltipPositionY;
  private firstItemFocusRef?: AfterRenderRef;
  panelAnimationState: 'void' | 'enter' = 'void';
  readonly animationDone = new Subject<AnimationEvent>();

  isAnimating?: boolean;

  @Input({ required: true }) title = '';
  @Input({ required: true }) content = '';

  @Input()
  get horizontalPosition(): TooltipPositionX {
    return this._xPosition;
  }
  set horizontalPosition(value: TooltipPositionX) {
    this._xPosition = value;
  }

  @Input()
  get verticalPosition(): TooltipPositionY {
    return this._yPosition;
  }
  set verticalPosition(value: TooltipPositionY) {
    this._yPosition = value;
  }

  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;
  @Input({ transform: booleanAttribute }) overlapTrigger: boolean;

  @Output()
  readonly closed: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
    const defaultOptions = TOOLTIP_DEFAULT_OPTIONS;
    this._xPosition = defaultOptions.xPosition;
    this._yPosition = defaultOptions.yPosition;
    this.overlapTrigger = defaultOptions.overlapTrigger;
  }

  ngOnDestroy() {
    this.closed.complete();
    this.firstItemFocusRef?.destroy();
  }

  startAnimation() {
    this.panelAnimationState = 'enter';
  }

  resetAnimation() {
    this.panelAnimationState = 'void';
  }

  onAnimationDone(event: AnimationEvent) {
    this.animationDone.next(event);
    this.isAnimating = false;
  }
}
