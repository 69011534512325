<div class="sch-input">
  <label *ngIf="label && variant === textboxVariants.CLASSIC" 
    class="sch-input-label {{variant}}" 
    for="{{ id }}" 
    [attr.readonly]="readonly" 
    [attr.disabled]="disabled">
    {{ label }}
  </label>

  <div class="position-relative">
    <input
      [mdbDatepicker]="datepicker"
      placeholder="{{ placeholder }}"
      type="text"
      id="{{ id }}"
      [disabled]="disabled"
      [value]="value"
      (keyup)="formatDate($event)"
      [placeholder]="convertToUpperCase(format)"
      [class.has-value]="value"
      [class.has-icon]="showDateIcon"
      [ngClass]="{'align-icon-start': alignIcon === 'left'}"
      maxlength="10"
      sch-input
      class="sch-input-field has-placeholder {{variant}}"
      autocomplete="{{autocomplete}}"
      [readonly]="readonly"
      [disabled]="disabled"
    />

    <label *ngIf="label && variant !== textboxVariants.CLASSIC" 
      class="sch-input-label {{variant}}" 
      for="{{ id }}" 
      [attr.readonly]="readonly" 
      [attr.disabled]="disabled">
      {{ label }}
    </label>

    <i
      class="sch-input-icon"
      [sch-icon]="'calendar'"
      (click)="openDatepicker()"
      [id]="id"
      *ngIf="showDateIcon"
    ></i>
    <!-- Icon to open datepicker -->
    <mdb-datepicker
      #datepicker
      [inline]="inline"
      (opened)="onDateOpened()"
      [format]="format"
      (closed)="onDateClosed()"
      [maxDate]="disableFuture ? futureDates : maxDate"
      [minDate]="disablePast ? pastDates : minDate"
      (dateChanged)="onDateChanged($event)"
      [disabled]="disabled"

    ></mdb-datepicker>
  </div>
</div>
