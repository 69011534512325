import { NgModule } from '@angular/core';
import { TooltipDirective, TooltipTriggerDirective } from '../directives';
import { DialogTooltipComponent, TooltipComponent} from '../components';

const TOOLTIP = [
  TooltipDirective,
  TooltipComponent,
  DialogTooltipComponent,
  TooltipTriggerDirective,
];

@NgModule({
  declarations: [],
  imports: [...TOOLTIP],
  exports: [...TOOLTIP],
})
export class TooltipModule {}
