import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'seech-shared-chip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
  @Input({ required: true }) description!: string;
  @Input() value!: string;
  @Input() color?: string;
  @Input() image?: string;
  @Input() fill?: 'solid' | 'outline' = 'solid';
  @Input() id?: string | number;
  // input to turn on and off the image placeholder
  @Input() createAvatar = true;
  // input to turn on and off the cancel button
  @Input() cancelable = false;

  @Output() cancelEvent = new EventEmitter<void>();

  getChipClasses(): string[] {
    const classes = ['sch-chip', `sch-chip-${this.color}`];
    if (this.fill === 'solid') {
      classes.push('filled');
    } else {
      classes.push('outlined');
    }
    return classes;
  }

  onCancel(): void {
    this.cancelEvent.emit();
  }

  // getFirstLetter(text: string): string {
  //   // remove spaces from the beginning and the end
  //   text = text.trim();
  //   // remove double or more spaces
  //   text = text.replace(/\s{2,}/g, ' ');
  //   // get first letter
  //   return text.charAt(0).toUpperCase();
  // }

  getFirstLetter(text: string): string {
    // provide a default value if text is undefined
    text = text ?? '';

    // remove spaces from the beginning and the end
    text = text.trim();

    // remove double or more spaces
    text = text.replace(/\s{2,}/g, ' ');

    // get first letter
    return text.charAt(0).toUpperCase();
  }
}
