import { Directive, HostBinding } from '@angular/core';
import {
  CDK_MENU,
  CdkMenu,
  PARENT_OR_NEW_INLINE_MENU_STACK_PROVIDER,
} from '@angular/cdk/menu';

@Directive({
  selector: '[sch-menu]',
  standalone: true,
  providers: [
    {
      provide: CdkMenu,
      useExisting: MenuDirective,
    },
    { provide: CDK_MENU, useExisting: MenuDirective },
    PARENT_OR_NEW_INLINE_MENU_STACK_PROVIDER('vertical'),
  ],
})
export class MenuDirective extends CdkMenu {
  @HostBinding('class') hostClass = 'sch-menu';
}
