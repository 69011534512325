<div class="dialog p-3">
    <div class="dialog-title">
        <div class="divider d-none d-md-inline-block bg-primary"></div>
        <p class="label d-none d-md-inline-block text-primary">Settings</p>
        <div class="divider bg-primary"></div>
        <i [sch-icon]="'cancel'" class="close text-primary" (click)="closeModal()" role="button"></i>
    </div>

    <div class="dialog-body">
        <div class="dialog-title mobile d-md-none mt-2 mx-2">
            <div class="divider"></div>
            <p class="label">Settings</p>
            <div class="divider"></div>
        </div>

        <div class="content-wrapper mb-3">
            <seech-accordion [borderless]="false">
                <seech-accordion-item [collapsed]="generalCollapsed">
                    <div item-header class="sch-accordion-item-header">
                        <i [sch-icon]="'home'"></i>
                        General
                    </div>
                    <div item-body>
                        <div class="my-1">
                            <div class="resets text-primary">
                                <p class="mb-0">Reset All</p>
                                <button (click)="generalReset()" sch-button [size]="'sm'" fill="solid"
                                    [sch-icon]="'auto-renew'" [id]="'general-reset'">
                                </button>
                            </div>

                            <ng-container *ngFor="let item of allSettings.general">
                                <div *ngIf="item.collapseAll"
                                    class="d-flex justify-content-between align-items-center sets mb-0">
                                    <p class="sub-title-box mb-0">
                                        {{item.collapseAll}}
                                    </p>

                                    <seech-switch [id]="'general-collapseAll'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.collapseAllValue" (selectedChange)="saveSettings()" />
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </seech-accordion-item>
            </seech-accordion>
        </div>

        <div class="content-wrapper mb-3">
            <seech-accordion [borderless]="false">
                <seech-accordion-item [collapsed]="controlsCollapsed">
                    <div item-header class="sch-accordion-item-header">
                        <i [sch-icon]="'toggle-off'"></i>
                        Controls
                    </div>
                    <div item-body>
                        <div class="my-1">
                            <div class="resets text-primary">
                                <p class="mb-0">Reset</p>
                                <button (click)="resetControlsSettings()" sch-button [size]="'sm'" fill="solid"
                                    [sch-icon]="'auto-renew'" [id]="'controls-reset'">
                                </button>
                            </div>

                            <ng-container *ngFor="let item of allSettings.controls">
                                <div *ngIf="item.readonly"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.readonly}}
                                    </p>

                                    <seech-switch [id]="'readonly'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.readonlyValue" (selectedChange)="saveSettings()" />
                                </div>

                                <div *ngIf="item.disabled"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.disabled}}
                                    </p>

                                    <seech-switch [id]="'disabled'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.disabledValue" (selectedChange)="saveSettings()" />
                                </div>

                                <div *ngIf="item.showValues"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.showValues}}
                                    </p>

                                    <seech-switch [id]="'showValues'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.showValuesValue" (selectedChange)="saveSettings()" />
                                </div>

                                <div *ngIf="item.autoComplete"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.autoComplete}}
                                    </p>

                                    <seech-switch [id]="'autocomplete'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.autocompleteValue" (selectedChange)="saveSettings()" />
                                </div>

                                <div *ngIf="item.textboxVariant"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.textboxVariant}}
                                    </p>

                                    <seech-select [id]="'textbox-select'" [options]="item.textboxOptions"
                                        [(ngModel)]="item.textboxValue" [placeholder]="'Choose option'" [label]="''"
                                        (selected)="saveSettings()">
                                    </seech-select>
                                </div>

                                <div *ngIf="item.combobuttonVariant"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.combobuttonVariant}}
                                    </p>

                                    <seech-select [id]="'combobutton-select'" [options]="item.combobuttonOptions"
                                        [(ngModel)]="item.combobuttonValue" [placeholder]="'Choose option'" [label]="''"
                                        (selected)="saveSettings()">
                                    </seech-select>
                                </div>

                                <div *ngIf="item.dropdownVariant"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.dropdownVariant}}
                                    </p>

                                    <seech-select [id]="'dropdown-select'" [options]="item.dropdownOptions"
                                        [(ngModel)]="item.dropdownValue" [placeholder]="'Choose option'" [label]="''"
                                        (selected)="saveSettings()">
                                    </seech-select>
                                </div>

                                <div *ngIf="item.radio" class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.radio}}
                                    </p>

                                    <seech-select [id]="'radio-select'" [options]="item.radioOptions"
                                        [(ngModel)]="item.radioValue" [placeholder]="'Choose option'" [label]="''"
                                        (selected)="saveSettings()">
                                    </seech-select>
                                </div>

                                <div *ngIf="item.checkbox"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.checkbox}}
                                    </p>

                                    <seech-select [id]="'checkbox-select'" [options]="item.checkboxOptions"
                                        [(ngModel)]="item.checkboxValue" [placeholder]="'Choose option'" [label]="''"
                                        (selected)="saveSettings()">
                                    </seech-select>
                                </div>

                                <div *ngIf="item.buttonSize"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.buttonSize}}
                                    </p>

                                    <seech-select [id]="'buttonsize-select'" [options]="item.buttonSizeOptions"
                                        [(ngModel)]="item.buttonSizeValue" [placeholder]="'Choose option'" [label]="''"
                                        (selected)="saveSettings()">
                                    </seech-select>
                                </div>

                                <div *ngIf="item.buttonShape"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.buttonShape}}
                                    </p>

                                    <seech-select [id]="'buttonshape-select'" [options]="item.buttonshapeOptions"
                                        [(ngModel)]="item.buttonshapeValue" [placeholder]="'Choose option'" [label]="''"
                                        (selected)="saveSettings()">
                                    </seech-select>
                                </div>

                                <div *ngIf="item.buttonVariant"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.buttonVariant}}
                                    </p>

                                    <seech-select [id]="'buttonvariant-select'" [options]="item.buttonvariantOptions"
                                        [(ngModel)]="item.buttonvariantValue" [placeholder]="'Choose option'"
                                        [label]="''" (selected)="saveSettings()">
                                    </seech-select>
                                </div>

                                <div *ngIf="item.switchVariant"
                                    class="d-flex justify-content-between align-items-center sets mb-0">
                                    <p class="sub-title-box mb-0">
                                        {{item.switchVariant}}
                                    </p>

                                    <seech-select [id]="'switch-select'" [options]="item.switchOptions"
                                        [(ngModel)]="item.switchValue" [placeholder]="'Choose option'" [label]="''"
                                        (selected)="saveSettings()">
                                    </seech-select>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </seech-accordion-item>
            </seech-accordion>
        </div>

        <div class="content-wrapper mb-3">
            <seech-accordion [borderless]="false">
                <seech-accordion-item [collapsed]="mediaCollapsed">
                    <div item-header class="sch-accordion-item-header">
                        <i [sch-icon]="'play'"></i>
                        Media
                    </div>
                    <div item-body>
                        <div class="my-1">
                            <div class="resets mb-2 text-primary">
                                <p class="mb-0">Reset</p>
                                <button (click)="resetMediaSettings()" sch-button [size]="'sm'" fill="solid"
                                    [sch-icon]="'auto-renew'" [id]="'media-reset'">
                                </button>
                            </div>

                            <p class="sets media-header">Audio Settings</p>
                            <ng-container *ngFor="let item of allSettings.media.audio">
                                <div *ngIf="item.autoplay"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.autoplay}}
                                    </p>

                                    <seech-switch [id]="'audio-autoplay'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.autoplayValue" (selectedChange)="saveSettings()" />
                                </div>

                                <div *ngIf="item.showEqualizer"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.showEqualizer}}
                                    </p>

                                    <seech-switch [id]="'audio-equalizer'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.showEqualizerValue" (selectedChange)="saveSettings()" />
                                </div>

                                <div *ngIf="item.defaultVolume"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.defaultVolume}}
                                    </p>

                                    <seech-select [id]="'audio-volume'" [options]="item.defaultVolumeOptions"
                                        [(selectedValue)]="item.defaultVolumeValue" [placeholder]="'Choose option'"
                                        [label]="''" (selected)="saveSettings()">
                                    </seech-select>
                                </div>
                            </ng-container>

                            <p class="sets media-header">Video Settings</p>
                            <ng-container *ngFor="let item of allSettings.media.video">
                                <div *ngIf="item.autoplay"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.autoplay}}
                                    </p>

                                    <seech-switch [id]="'video-autoplay'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.autoplayValue" (selectedChange)="saveSettings()" />
                                </div>

                                <div *ngIf="item.muted" class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.muted}}
                                    </p>

                                    <seech-switch [id]="'video-muted'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.mutedValue" (selectedChange)="saveSettings()" />
                                </div>

                                <div *ngIf="item.videoQuality"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.videoQuality}}
                                    </p>

                                    <seech-switch [id]="'video-quality'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.videoQualityValue" (selectedChange)="saveSettings()" />
                                </div>

                                <div *ngIf="item.enableSubtitle"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.enableSubtitle}}
                                    </p>

                                    <seech-switch [id]="'enable-sutitle'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.enableSubtitleValue" (selectedChange)="saveSettings()" />
                                </div>

                                <div *ngIf="item.loopVideo"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.loopVideo}}
                                    </p>

                                    <seech-switch [id]="'loop-video'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.loopVideoValue" (selectedChange)="saveSettings()" />
                                </div>

                                <div *ngIf="item.enableFullscreen"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.enableFullscreen}}
                                    </p>

                                    <seech-switch [id]="'enable-fullscreen'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.enableFullscreenValue" (selectedChange)="saveSettings()" />
                                </div>

                                <div *ngIf="item.enablePIP"
                                    class="d-flex justify-content-between align-items-center sets">
                                    <p class="sub-title-box mb-0">
                                        {{item.enablePIP}}
                                    </p>

                                    <seech-switch [id]="'enable-pip'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.enablePIPValue" (selectedChange)="saveSettings()" />
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </seech-accordion-item>
            </seech-accordion>
        </div>

        <div class="content-wrapper">
            <seech-accordion [borderless]="false">
                <seech-accordion-item [collapsed]="iconsCollapsed">
                    <div item-header class="sch-accordion-item-header">
                        <i [sch-icon]="'priority'"></i>
                        Icons
                    </div>
                    <div item-body>
                        <div class="my-1">
                            <div class="resets text-primary">
                                <p class="mb-0">Reset</p>
                                <button (click)="resetIconsSettings()" sch-button [size]="'sm'" fill="solid"
                                    [sch-icon]="'auto-renew'" [id]="'icons-reset'">
                                </button>
                            </div>

                            <ng-container *ngFor="let item of allSettings.icons">
                                <div *ngIf="item.iconVariant"
                                    class="d-flex justify-content-between align-items-center sets mb-0">
                                    <p class="sub-title-box mb-0">
                                        {{item.iconVariantValue ? 'Fill' : 'Outline'}} {{item.iconVariant}}
                                    </p>

                                    <seech-switch [id]="'icon-variant'" [color]="'primary'" [disabled]="false"
                                        [(selected)]="item.iconVariantValue" (selectedChange)="saveSettings()" />
                                </div>

                                <div *ngIf="item.iconWeight">
                                    <p class="mt-3 mb-0 media-header">{{item.iconWeight}}</p>
                                    <mdb-range [min]="minIconWeight" [max]="maxIconWeight"
                                        [value]="item.iconWeightValue"
                                        (rangeValueChange)="item.iconWeightValue = $event.value; saveSettings()">
                                    </mdb-range>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <p class="mb-0 desc">{{minIconWeight}}</p>
                                        <p class="mb-0 desc">{{maxIconWeight}}</p>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </seech-accordion-item>
            </seech-accordion>
        </div>
    </div>
</div>