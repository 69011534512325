<div mdbDropdown class="dropdown">
  <div
    class="btn-group btn-group-{{ size }} shadow-none"
    [ngClass]="btnGroupClass"
    role="group"
    [id]="id"
  >
    <button
      sch-button
      [variant]="variant"
      [shape]="shape"
      [size]="size"
      [color]="color"
      [fontColor]="fontColor"
      [disabled]="disabled"
      [hideBorder]="hideBorder"
      (click)="onClick()"
      class="main-btn"
    >
      <ng-container *ngIf="label; else customLabel">
        <span> {{ label }} </span>
      </ng-container>

      <ng-template #customLabel>
        <ng-content select="[label]"></ng-content>
      </ng-template>
    </button>

    <button
      sch-button
      [variant]="variant"
      [shape]="shape"
      [size]="size"
      [color]="color"
      [fontColor]="fontColor"
      [disabled]="disabled"
      [hideBorder]="hideBorder"
      class="ms-0 toggle-btn"
      mdbDropdownToggle
      (click)="toggleClicked.emit()"
    >
      <i [sch-icon]="'collapse'"></i>
    </button>
  </div>

  <ul
    [id]="computeId('dropdown-menu')"
    mdbDropdownMenu
    class="dropdown-menu"
    [class]="menuAlignmentClasses"
    [class.d-none]="dropdownItems.length <= 0"
    [attr.aria-labelledby]="id"
  >
    <li *ngFor="let item of dropdownItems" id="{{ item?.id }}">
      <a
        sch-dropdown-item
        class="dropdown-item"
        [ngClass]="{ active: activeItem === item.value }"
        href="javascript:"
        (click)="onListItemClicked(item)"
      >
        <i [sch-icon]="item.icon" *ngIf="item.icon"></i>

        {{ item.label }}
      </a>
    </li>
  </ul>
</div>
