import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';

import { CONTROL_TYPE } from '../../constants';
import { InputDirective } from '../../directives';
import { ValidationComponent } from '../validation/validation.component';
import { InputComponent } from '../input/input.component';
import { IconDirective } from '@seech/shared-ng';
import { SharedTooltipDirective, TextboxVariant } from '@seech/shared-ng';
import { Alignment } from '../../models';

@Component({
  selector: 'seech-passcode',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MdbFormsModule,
    InputDirective,
    ValidationComponent,
    SharedTooltipDirective,
    InputComponent,
    IconDirective
  ],
  templateUrl: './passcode.component.html',
  styleUrls: ['./passcode.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasscodeComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasscodeComponent),
      multi: true
    }
  ]
})
export class PasscodeComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() label?: string;
  @Input() id = '';
  @Input() value = '';
  @Input() placeholder = 'Enter passcode';
  @Input() disabled = false;
  @Input() alignIcon: Alignment = 'right';
  @Input() variant!: TextboxVariant;

  /** Ensures only digits are allowed, e.g. 4–6 digits by default. */
  @Input() minLength = 4;
  @Input() maxLength = 6;

  /** Set allowable minimum/maximum numeric values (e.g., minValue=1000, maxValue=9999). */
  @Input() minValue?: number;
  @Input() maxValue?: number;

  /** Fired each time the underlying model changes. */
  @Output() valueChange = new EventEmitter<string>();

  /** Emits validation status changes. */
  @Output() valid = new EventEmitter<boolean>();

  /** Emits when input loses focus. */
  @Output() blurred = new EventEmitter<void>();

  passcodeVisible = false;

  ngOnInit(): void { }

  // ControlValueAccessor methods
  writeValue(value: string): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onChange = (value: string) => { };
  private onTouched = () => { };

  onInput(event: Event): void {
    let inputValue = (event.target as HTMLInputElement).value;

    // Remove any non-numeric characters
    inputValue = inputValue.replace(/\D/g, '');

    // Enforce max length
    if (inputValue.length > this.maxLength) {
      inputValue = inputValue.slice(0, this.maxLength);
    }

    this.value = inputValue;
    this.valueChange.emit(this.value);
    this.onChange(this.value);

    // Validate passcode
    const validationResult = this.validate({ value: this.value } as AbstractControl);
    this.valid.emit(validationResult === null);
    this.onTouched();
  }

  /** Prevent non-numeric characters from being typed */
  onKeydown(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    if (!allowedKeys.includes(event.key) && !/^\d$/.test(event.key)) {
      event.preventDefault();
    }
  }


  onBlur(): void {
    this.onTouched();
    this.blurred.emit();
  }

  handleVisibility(): void {
    this.passcodeVisible = !this.passcodeVisible;
  }

  /** Validation method for Angular Forms */
  validate(control: AbstractControl): ValidationErrors | null {
    const rawValue = control.value;

    // Check if the field is required
    const isRequired = control.validator ? control.validator(new FormControl())?.['required'] : false;

    if (isRequired && (!rawValue || rawValue.trim() === '')) {
      return { required: true };
    }

    if (rawValue) {
      // Ensure input contains only numbers
      if (!/^\d+$/.test(rawValue)) {
        return { digitsOnly: true };
      }

      // Check length boundaries
      if (rawValue.length < this.minLength) {
        return { tooShort: true };
      }
      if (this.maxLength && rawValue.length > this.maxLength) {
        return { tooLong: true };
      }

      // Check numeric boundaries if applicable
      const numericValue = parseInt(rawValue, 10);
      if (this.minValue != null && numericValue < this.minValue) {
        return { minValue: true };
      }
      if (this.maxValue != null && numericValue > this.maxValue) {
        return { maxValue: true };
      }
    }

    return null; // No errors
  }

}
