 
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdbSelectModule } from 'mdb-angular-ui-kit/select';
import { AbstractControl, FormsModule, NG_VALIDATORS, ReactiveFormsModule, ValidationErrors, Validator } from '@angular/forms';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconDirective, SharedScrollEndNotifierDirective, SpinnerComponent, TextboxVariant } from '@seech/shared-ng';
import {  Validators,} from '@angular/forms';
import { ControlsConfigService } from '../../injection/controls-config.service';
import { TEXTBOX_VARIANT } from '../../constants';

export interface SelectOption{
  label: string;
  value: any;
  hideOption?: boolean;
}

@Component({
  selector: 'seech-select',
  standalone: true,
  imports: [
    CommonModule,
    MdbDropdownModule,
    MdbRippleModule,
    FormsModule,
    ReactiveFormsModule,
    MdbSelectModule,
    IconDirective,
    SharedScrollEndNotifierDirective,
    SpinnerComponent
  ],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SelectComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
  filteredOptions: SelectOption[] = [];

  @Input() selectedValue: any = '';
  @Input() options: SelectOption[] = [];
  @Input() label?:string;
  @Input() id = '';
  @Input() isContentLoading = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() showSelected = false;
  @Input() disableSearch = false;
  @Input() autocomplete: 'on' | 'off' = 'off';
  @Input() threshold = 100;
  @Input() placeholder = '';
  @Input() readonlyplaceholder = '';
  @Input() variant!: TextboxVariant;

  @Output() selectedValueChange = new EventEmitter<any>();
  @Output() closed = new EventEmitter<boolean>();
  @Output() opened = new EventEmitter<boolean>();
  @Output() selected = new EventEmitter<SelectOption>();
  @Output() reachedEndOfList = new EventEmitter<void>();
  @Output() searchTermChange = new EventEmitter<string>();

  textboxVariants = TEXTBOX_VARIANT;
  showDropdown = false;
  @ViewChild('dropdownMenu') dropdownMenu!: ElementRef;

  private onChange = (value: any) => { };
  private onTouched = () => { };

  constructor(
    private eRef: ElementRef, 
    private configService: ControlsConfigService,
    private cdr: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['options']) {
      this.filteredOptions = [...this.options];
    }
  }

  ngOnInit(): void {
    if (!this.variant) {
      const config = this.configService.getConfig();
      this.variant = config?.textboxVariant ? config.textboxVariant : 'classic';
    }
  }

  get selectedLabel(): string {
    if(!this.selectedValue) return '';
    return this.options.find(x => x.value === this.selectedValue)?.label ?? '';
  }

  selectOption(option: SelectOption) {
    this.selectedValue = option.value;
    this.onChange(option.value);
    this.onTouched();
    this.selectedValueChange.emit(option);
    this.selected.emit(option);
    this.cdr.detectChanges();
    this.toggleDropdown();
  }

  onInput(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    this.showDropdown = true;
    const value = event.target.value;
    this.filteredOptions = event ? this.options.filter(x => x.label.toLowerCase().includes(value.toLowerCase())) : this.options;
    this.searchTermChange.emit(value);

     // Hide the dropdown if no results are found
     if (this.filteredOptions.length === 0) {
      this.showDropdown = false;
    }
  }

  writeValue(value: any): void {
    if(value){
      this.selectedValue = value;
    }
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    this.showDropdown === true ? this.opened.emit(true) : this.opened.emit(false);
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // return this.selectedValue ? null : { required: true };
    const value = control.value;
    const valueIsRequired = control.hasValidator(Validators.required);
    const requiredError = { required: true };
    if (!value && valueIsRequired) {
      return requiredError;
    }
    return null;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this.eRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.showDropdown) {
      this.toggleDropdown();
      this.closed.emit(true);
      this.opened.emit(false);
    }
  }

  onScrollEnd(){
    this.reachedEndOfList.emit();
  }

}