import { CommonModule } from '@angular/common';
import { Icon, IconDirective } from '@seech/shared-ng';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'seech-side-nav-toggle',
  standalone: true,
  imports: [CommonModule, IconDirective],
  templateUrl: './side-nav-toggle.component.html',
  styleUrls: ['./side-nav-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavToggleComponent {
  @Input() isMobile = false;
  @Input() position: 'start' | 'end' = 'start';
  @Input() id: string | number | null | undefined;
  @Input() toggleIcon: Icon = 'prev-arrow';

  @Output() sideNavClose = new EventEmitter<void>();
  @Output() toggleMinimize = new EventEmitter<void>();
}
