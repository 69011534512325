<div id="{{ id }}" class="seech-switch w-full" 
[ngClass]="{'flex-row': alignLabel === 'left', 'modern': variant === 'modern'}">
  <div *ngIf="label">
    <label class="form-check-label mdb-text" for="{{ label }}">{{ label }}</label>
  </div>

  <div class="form-check form-switch mb-0" sch-color [color]="color">
    <div>
      <input
        mdbCheckbox
        class="form-check-input"
        type="checkbox"
        id="{{ id }}"
        [checked]="selected"
        [disabled]="disabled"
        (change)="onSwitchChange($event)"
      />
    </div>
  </div>
</div>
