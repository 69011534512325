<div class="stars" [class.disabled]="disabled" [ngStyle]="{'column-gap': gap}">
  <svg
    *ngFor="let star of stars"
    [ngClass]="rate && star <= rate ? 'filled' : 'empty'"
    class="mx-[2px]"
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width ? width : '11'"
    [attr.height]="height ? height : '10'"
    viewBox="0 0 13 12"
    fill="none"
    (click)="setRating(star)"
    [style.pointer-events]="disabled ? 'none' : 'auto'"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      [style.fill]="star <= rate ? color : ''"
      d="M12.4344 4.09119L8.8544 3.7623C8.62872 3.74237 8.43371 3.60284 8.34149 3.39355L7.05922 0.373739C6.85406 -0.12458 6.12563 -0.12458 5.92047 0.373739L4.64846 3.39355C4.56639 3.60284 4.36123 3.74237 4.13555 3.7623L0.555458 4.09119C0.0220342 4.14103 -0.193387 4.78884 0.206681 5.13766L2.90457 7.43989C3.07896 7.58939 3.15077 7.80865 3.09948 8.02791L2.28908 11.2569C2.16599 11.7653 2.73018 12.1838 3.20206 11.9148L6.18718 10.2104C6.38208 10.1008 6.61802 10.1008 6.81293 10.2104L9.79815 11.9148C10.27 12.1838 10.8342 11.7753 10.711 11.2569L9.91088 8.02791C9.85959 7.80865 9.9314 7.58939 10.1058 7.43989L12.8037 5.13766C13.1935 4.78884 12.9678 4.14103 12.4344 4.09119Z"
      fill="#EF790D"
    />
  </svg>
  <span class="reviews-count" *ngIf="reviewCount">{{ reviewCount }}</span>
</div>
