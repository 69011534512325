import DOMPurify from 'isomorphic-dompurify';
import { AppModule } from './app/app.module';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
const globalWindow = window as any;

/**
 * Though we can stop ourself from writing a code that can result in XSS, third party libraries hardly do
 * that and they hardly care
 */
if (globalWindow.trustedTypes && globalWindow.trustedTypes.createPolicy) {
  /**
   * This creates a security policy in addition to that of angular security policy for any code that renders html in the DOM
   * XSS problem comes from a code that renders HTML to the DOM like
   * document.innerHTML = "Some String that can contain html code like <script>some dangerous javascript</script>"
   */
  globalWindow.trustedTypes.createPolicy('default', {
    /**
     * Before the browser executes any script that renders HTML string to the DOM, the browser should show that string
     * Then DOMPurify or the like of it as there are my out there removes
     * anything that is dangerous from the string and returns it back to the browser for rendering,
     * all of this happens in development mode and is not the bad code does not make it production
     */
    createHTML: (string) => DOMPurify.sanitize(string),
    createScript: (script) => script,
    createScriptURL: (url) => url,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
