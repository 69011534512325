<div id="{{ id }}" class="sch-chip" [ngClass]="getChipClasses()">
  <div *ngIf="image" class="sch-chip-img">
    <img [src]="image" class="" alt="" />
  </div>
  <div
    *ngIf="!image && createAvatar"
    class="sch-chip-img sch-chip-image-placeholder"
  >
    <span class="sch-chip-letter">{{ getFirstLetter(description) }}</span>
  </div>

  <div class="sch-chip-text">
    {{ description }}
  </div>

  <span *ngIf="cancelable" [id]="id" class="sch-chip-cancel" (click)="onCancel()">&times;</span>
</div>
