import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import { LocationService } from '../../services';

export enum MAPTYPE {
  ROADMAP = 'roadmap',
  SATELLITE = 'satellite',
  HYBRID = 'hybrid',
  TERRAIN = 'terrain',
}

// eslint-disable-next-line no-var
declare var google: any;
@Component({
  standalone: true,
  selector: 'seech-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  imports: [CommonModule],
})
export class MapComponent implements AfterViewInit, OnDestroy, OnChanges {
  // Map Configuration Inputs
  @Input() mapEnabled = true;
  @Input() center: { lat: number; lng: number } = { lat: 0, lng: 0 };
  @Input() zoom = 8;
  @Input() mapTypeId: MAPTYPE = MAPTYPE.ROADMAP;
  @Input() mapDraggable = true;
  @Input() markerDraggable = true;
  @Input() disableDefaultUI = false;
  @Input() mapTypeControl = false;
  @Input() scaleControl = true;
  @Input() streetViewControl = true;
  @Input() rotateControl = true;
  @Input() fullscreenControl = true;
  @Input() styles: any[] = [];
  @Input() gestureHandling: 'cooperative' | 'greedy' | 'none' | 'auto' = 'auto';

  // Overlays Inputs
  @Input() markers: any[] = [];
  @Input() polylines: any[] = [];
  @Input() circles: any[] = [];
  @Input() polygons: any[] = [];
  @Input() rectangles: any[] = [];

  // Map Event Outputs
  @Output() mapClick = new EventEmitter<any>();
  @Output() mapDblClick = new EventEmitter<any>();
  @Output() mapDrag = new EventEmitter<void>();
  @Output() mapDragEnd = new EventEmitter<void>();
  @Output() mapDragStart = new EventEmitter<void>();
  @Output() mapIdle = new EventEmitter<void>();
  @Output() mapZoomChanged = new EventEmitter<void>();
  @Output() mapCenterChanged = new EventEmitter<void>();

  // Overlay Event Outputs
  @Output() markerClick = new EventEmitter<any>();
  @Output() polylineClick = new EventEmitter<any>();
  @Output() circleClick = new EventEmitter<any>();
  @Output() polygonClick = new EventEmitter<any>();
  @Output() rectangleClick = new EventEmitter<any>();

  private map: any;
  private isBrowser: boolean;
  private markerRefs: any[] = [];

  constructor(
    private elementRef: ElementRef,
    private locationService: LocationService,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit(): void {
    // return
    if (this.isBrowser) {
      this.locationService
        .load(this.mapEnabled)
        .then(() => this.initializeMap())
        .catch((error) => console.error('Error loading map:', error));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.map) {
      this.applyMapOptions(changes);
      this.updateOverlays(changes);
    }
  }

  ngOnDestroy(): void {
    if (this.map) {
      google.maps.event.clearInstanceListeners(this.map);
    }
    this.clearAllOverlays();
  }

  protected initializeMap(): void {
    const container =
      this.elementRef.nativeElement.querySelector('.map-container');
    const mapOptions: any = {
      center: this.center,
      zoom: this.zoom,
      mapTypeId: this.getMapTypeId(this.mapTypeId),
      draggable: this.mapDraggable,
      disableDefaultUI: this.disableDefaultUI,
      mapTypeControl: this.mapTypeControl,
      scaleControl: this.scaleControl,
      streetViewControl: this.streetViewControl,
      rotateControl: this.rotateControl,
      fullscreenControl: this.fullscreenControl,
      styles: this.styles,
      gestureHandling: this.gestureHandling,
    };

    this.map = new google.maps.Map(container, mapOptions);
    this.addMapEventListeners();
    this.renderAllOverlays();
  }

  private getMapTypeId(mapTypeId: MAPTYPE): string {
    switch (mapTypeId) {
      case MAPTYPE.SATELLITE:
        return google.maps.MapTypeId.SATELLITE;
      case MAPTYPE.HYBRID:
        return google.maps.MapTypeId.HYBRID;
      case MAPTYPE.TERRAIN:
        return google.maps.MapTypeId.TERRAIN;
      default:
        return google.maps.MapTypeId.ROADMAP;
    }
  }

  private applyMapOptions(changes: SimpleChanges): void {
    const options: any = {};

    if (changes['center']) {
      options.center = this.center;
    }
    if (changes['zoom']) {
      options.zoom = this.zoom;
    }
    if (changes['mapTypeId']) {
      options.mapTypeId = this.getMapTypeId(this.mapTypeId);
    }
    if (changes['draggable']) {
      options.draggable = this.mapDraggable;
    }
    if (changes['disableDefaultUI']) {
      options.disableDefaultUI = this.disableDefaultUI;
    }
    if (changes['styles']) {
      options.styles = this.styles;
    }
    if (changes['gestureHandling']) {
      options.gestureHandling = this.gestureHandling;
    }

    this.map.setOptions(options);
  }

  private addMapEventListeners(): void {
    this.map.addListener('click', (event: any) => {
      this.mapClick.emit(event);
    });
    this.map.addListener('dblclick', (event: any) => {
      this.mapDblClick.emit(event);
    });
    this.map.addListener('drag', () => {
      this.mapDrag.emit();
    });
    this.map.addListener('dragend', () => {
      this.mapDragEnd.emit();
    });
    this.map.addListener('dragstart', () => {
      this.mapDragStart.emit();
    });
    this.map.addListener('idle', () => {
      this.mapIdle.emit();
    });
    this.map.addListener('zoom_changed', () => {
      this.mapZoomChanged.emit();
    });
    this.map.addListener('center_changed', () => {
      this.mapCenterChanged.emit();
    });
  }

  private updateOverlays(changes: SimpleChanges): void {
    if (changes['markers']) {
      this.updateMarkers();
    }
    if (changes['polylines']) {
      this.updatePolylines();
    }
    if (changes['circles']) {
      this.updateCircles();
    }
    if (changes['polygons']) {
      this.updatePolygons();
    }
    if (changes['rectangles']) {
      this.updateRectangles();
    }
  }

  private updateMarkers(): void {
    this.clearMarkers();

    this.markers.forEach((options: any, index: number) => {
      const marker = new google.maps.Marker({
        ...options,
        map: this.map,
        draggable: this.markerDraggable,
      });

      // Add marker to markerRefs array
      this.markerRefs.push(marker);

      // Add an event listener for when the marker is dragged
      marker.addListener('dragend', (event: any) => {
        const newPosition = event.latLng;

        // Update the marker's position in the markers array
        this.markers[index].position = {
          lat: newPosition.lat(),
          lng: newPosition.lng(),
        };

        // Optionally, emit an event to notify other parts of the application
        this.markerClick.emit(this.markers[index]);
      });

      // Add a click listener for the marker if needed
      marker.addListener('click', () => {
        this.markerClick.emit(marker);
      });
    });
  }

  private clearMarkers(): void {
    // Remove each marker from the map
    this.markerRefs.forEach((marker) => marker.setMap(null));
    // Clear the markerRefs array
    this.markerRefs = [];
  }

  private updatePolylines(): void {
    // Logic to update polylines
  }

  private updateCircles(): void {
    // Logic to update circles
  }

  private updatePolygons(): void {
    // Logic to update polygons
  }

  private updateRectangles(): void {
    // Logic to update rectangles
  }

  private clearAllOverlays(): void {
    this.clearMarkers();
    // Logic to clear other overlays
  }

  private renderAllOverlays(): void {
    this.updateMarkers();
    this.updatePolylines();
    this.updateCircles();
    this.updatePolygons();
    this.updateRectangles();
  }
}
