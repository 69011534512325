 
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ControlValueAccessor,
} from '@angular/forms';
import { LocationService } from '../../services';
import { ComboSelectComponent } from '../combo-select/combo-select.component';
import { Address, Coordinates, Geolocation } from '@seech/shared-logic-ng';

// eslint-disable-next-line no-var
declare var google: any;

@Component({
  standalone: true,
  selector: 'seech-location',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComboSelectComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LocationComponent),
      multi: true,
    },
  ],
})
export class LocationComponent
  implements AfterViewInit, OnChanges, ControlValueAccessor
{
  @ViewChild('locationInput', { static: true })
  locationInput!: ComboSelectComponent;

  @Input() mapEnabled = false;
  @Input() autoSelect = true;
  @Input() chipsColor = 'info';
  @Input() results: any[] = [];
  @Input() value: string | string[] | any = '';
  @Input() id: string | number | null | undefined;
  @Input() placeholder = 'Enter a location';
  @Input() hideBorder = false;
  @Input() maxRowCount = 2;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() label?: string;
  @Input() coordinates!: Coordinates | Coordinates[];
  @Input() maxSelectionCount = 1;

  @Output() placeSelected = new EventEmitter<Geolocation>();
  @Output() selectionLimitReached = new EventEmitter<boolean>();

  protected formattedLocation!: Coordinates;
  protected address: Address = {};

  private onChange = (value: boolean) => {};
  private onTouched = () => {};

  constructor(
    private cdr: ChangeDetectorRef,
    private locationService: LocationService
  ) {}

  ngAfterViewInit(): void {
    this.locationService
      .load(this.mapEnabled)
      .then(() => {
        this.initialize_autocomplete();
      })
      .catch((error) => {
        console.error('Error loading Google Maps API:', error);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['coordinates'] && changes['coordinates'].currentValue) {
      const coords = changes['coordinates'].currentValue;

      if (Array.isArray(coords)) {
        // If it's an array, process each coordinate
        coords.forEach((coord) => this.processCoordinate(coord));
      } else if (coords && typeof coords === 'object') {
        // If it's a single object, process it
        this.processCoordinate(coords);
      } else {
        console.warn('Invalid coordinates passed:', coords);
      }
    }
  }

  protected initialize_autocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.locationInput.comboInput.nativeElement
    );

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (place.geometry) {
        let address = '';

        if (place.address_components) {
          address = [
            (place.address_components[0] &&
              place.address_components[0].short_name) ||
              '',
            (place.address_components[1] &&
              place.address_components[1].short_name) ||
              '',
            (place.address_components[2] &&
              place.address_components[2].short_name) ||
              '',
            (place.address_components[3] &&
              place.address_components[3].short_name) ||
              '',
            (place.address_components[4] &&
              place.address_components[4].long_name) ||
              '',
            (place.address_components[5] &&
              place.address_components[5].long_name) ||
              '',
            (place.address_components[6] &&
              place.address_components[6].long_name) ||
              '',
          ].join(' ');
        }

        this.formattedLocation = {
          location: this.locationInput.comboInput.nativeElement.value.trim(),
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
          coords:
            place.geometry.location.lat() +
            ', ' +
            place.geometry.location.lng(),
        };

        this.address = this.extractAddress(place.address_components);
        this.value = this.locationInput.comboInput.nativeElement.value;
        this.cdr.detectChanges();

        this.placeSelected.emit({
          coordinates: this.formattedLocation,
          address: this.address,
        });

        if (this.autoSelect) {
          this.triggerEnterKey();
        }
      }
    });
  }

  private extractAddress(components: any[]): Address {
    const address: Address = {};

    components.forEach((component) => {
      if (component.types.includes('street_number')) {
        address.street = component.long_name;
      } else if (component.types.includes('route')) {
        address.street =
          (address.street ? address.street + ' ' : '') + component.long_name;
      } else if (component.types.includes('locality')) {
        address.city = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        address.state = component.long_name;
      } else if (component.types.includes('country')) {
        address.country = component.long_name;
        address.countryCode = component.short_name; // Capture country code
      } else if (component.types.includes('postal_code')) {
        address.postalCode = component.long_name;
      }
    });

    return address;
  }

  // Refactored function to get the full Address object using lat, lng
  private getAddress(coords: Coordinates): void {
    setTimeout(() => {
      //prefill the selected address with the location string
      this.locationInput.inputValue = coords.location;
      this.triggerEnterKey();
    }, 500);

    const geocoder = new google.maps.Geocoder();
    const latLng = new google.maps.LatLng(coords.latitude, coords.longitude);

    geocoder.geocode({ location: latLng }, (results: any[], status: string) => {
      if (status === 'OK' && results[0]) {
        this.address = this.extractAddress(results[0].address_components);

        // Emit coordinates and address
        const updatedCoords: Coordinates = {
          ...coords,
          location: results[0].formatted_address,
        };

        this.placeSelected.emit({
          coordinates: updatedCoords,
          address: this.address,
        });
      }
    });
  }

  // New method to get current location using the Geolocation API
  getCurrentLocation() {
    this.locationService.getCurrentLocation(true).subscribe(
      (coords) => {
        this.getAddress(coords);
      },
      (error) => {
        console.error('Failed to get location:', error);
      }
    );
  }

  // Method to trigger the enter keystroke
  protected triggerEnterKey() {
    const event = new KeyboardEvent('keyup', {
      bubbles: true,
      cancelable: true,
      key: 'Enter',
      code: 'Enter',
    });
    this.locationInput.comboInput.nativeElement.dispatchEvent(event);
  }

  onSelectionLimitReached(event: boolean) {
    this.selectionLimitReached.emit(event);
  }

  private async processCoordinate(coord: Coordinates): Promise<void> {
    // Check if coordinates have changed
    if (
      !this.formattedLocation ||
      coord.latitude !== this.formattedLocation.latitude ||
      coord.longitude !== this.formattedLocation.longitude
    ) {
      await this.getAddress(coord);
    }
  }

  writeValue(value: any): void {
    if (!value) {
      this.value = '';
      this.locationInput.inputValue = '';
      return;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        this.value = value
          .map((coord: Coordinates) => coord.location || '')
          .join(', ');
        value.forEach((coord) => this.processCoordinate(coord));
      } else if (value.latitude && value.longitude) {
        const coords: Coordinates = value;
        this.value = coords.location || '';
        this.locationInput.inputValue = coords.location || '';
        this.processCoordinate(coords);
      }
    } else {
      console.warn('Unsupported value type provided to writeValue:', value);
    }

    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
