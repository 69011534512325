import { MediaSettings } from './constant/constant';
import {
  BTN,
  FILL,
  SIZE,
  TEXTBOXVARIANT,
  NORMALROUNDED,
  NORMALCIRCLE,
  SWITCHVARIANT,
} from '@seech/shared-ng';

export const DefaultControlsSettings = {
  readonlyValue: false,
  disabledValue: false,
  showValuesValue: false,
  autocompleteValue: false,
  textboxValue: TEXTBOXVARIANT.HYBRID,
  combobuttonValue: NORMALROUNDED.ROUNDED,
  dropdownValue: NORMALROUNDED.ROUNDED,
  radioValue: NORMALCIRCLE.NORMAL,
  checkboxValue: NORMALCIRCLE.CIRCLE,
  buttonSizeValue: SIZE.MEDIUM,
  buttonshapeValue: BTN.SHAPE.NORMAL,
  buttonvariantValue: FILL.SOLID,
  switchValue: SWITCHVARIANT.MODERN,
};

export const DefaultMediaSettings: MediaSettings = {
  audio: {
    autoplayValue: false,
    showEqualizerValue: false,
    defaultVolumeValue: '50%',
  },

  video: {
    autoplayValue: true,
    mutedValue: false,
    videoQualityValue: false,
    enableSubtitleValue: false,
    loopVideoValue: false,
    enableFullscreenValue: false,
    enablePIPValue: false,
  },
};

export const DefaultIconSettings = {
  iconVariantValue: false,
  iconWeightValue: '400',
};

export const AllSettings = {
  general: [{ collapseAll: 'Collapse All', collapseAllValue: false }],
  controls: [
    {
      readonly: 'Read Only',
      readonlyValue: DefaultControlsSettings.readonlyValue,
    },
    {
      disabled: 'Disabled',
      disabledValue: DefaultControlsSettings.disabledValue,
    },
    {
      showValues: 'Show Values',
      showValuesValue: DefaultControlsSettings.showValuesValue,
    },
    {
      autoComplete: 'Auto-Complete',
      autocompleteValue: DefaultControlsSettings.autocompleteValue,
    },
    {
      textboxVariant: 'Textbox Variant',
      textboxOptions: [
        { value: 'Modern', label: 'Modern' },
        { value: 'Classic', label: 'Classic' },
        { value: 'Hybrid', label: 'Hybrid' },
      ],
      textboxValue:
        DefaultControlsSettings.textboxValue.charAt(0).toUpperCase() +
        DefaultControlsSettings.textboxValue.substring(1),
    },
    {
      combobuttonVariant: 'Combo Button Variant',
      combobuttonOptions: [
        { value: 'Normal', label: 'Normal' },
        { value: 'Rounded', label: 'Rounded' },
      ],
      combobuttonValue:
        DefaultControlsSettings.combobuttonValue.charAt(0).toUpperCase() +
        DefaultControlsSettings.combobuttonValue.substring(1),
    },
    {
      dropdownVariant: 'Dropdown Variant',
      dropdownOptions: [
        { value: 'Normal', label: 'Normal' },
        { value: 'Rounded', label: 'Rounded' },
      ],
      dropdownValue:
        DefaultControlsSettings.dropdownValue.charAt(0).toUpperCase() +
        DefaultControlsSettings.dropdownValue.substring(1),
    },
    {
      radio: 'Radio Button Variant',
      radioOptions: [
        { value: 'Normal', label: 'Normal' },
        { value: 'Circle', label: 'Circle' },
      ],
      radioValue:
        DefaultControlsSettings.radioValue.charAt(0).toUpperCase() +
        DefaultControlsSettings.radioValue.substring(1),
    },
    {
      checkbox: 'Checkbox',
      checkboxOptions: [
        { value: 'Normal', label: 'Normal' },
        { value: 'Circle', label: 'Circle' },
      ],
      checkboxValue:
        DefaultControlsSettings.checkboxValue.charAt(0).toUpperCase() +
        DefaultControlsSettings.checkboxValue.substring(1),
    },
    {
      buttonSize: 'Button Size',
      buttonSizeOptions: [
        { value: 'Sm', label: 'Small' },
        { value: 'Md', label: 'Medium' },
        { value: 'Lg', label: 'Large' },
      ],
      buttonSizeValue:
        DefaultControlsSettings.buttonSizeValue.charAt(0).toUpperCase() +
        DefaultControlsSettings.buttonSizeValue.substring(1),
    },
    {
      buttonShape: 'Button Shape',
      buttonshapeOptions: [
        { value: 'Normal', label: 'Normal' },
        { value: 'Rounded', label: 'Rounded' },
        { value: 'Circle', label: 'Circle' },
      ],
      buttonshapeValue:
        DefaultControlsSettings.buttonshapeValue.charAt(0).toUpperCase() +
        DefaultControlsSettings.buttonshapeValue.substring(1),
    },
    {
      buttonVariant: 'Button Variant',
      buttonvariantOptions: [
        { value: 'Solid', label: 'Solid' },
        { value: 'Outline', label: 'Outline' },
        { value: 'Shaded', label: 'Shaded' },
      ],
      buttonvariantValue:
        DefaultControlsSettings.buttonvariantValue.charAt(0).toUpperCase() +
        DefaultControlsSettings.buttonvariantValue.substring(1),
    },
    {
      switchVariant: 'Switch Variant',
      switchOptions: [
        { value: 'Classic', label: 'Classic' },
        { value: 'Modern', label: 'Modern' },
      ],
      switchValue: DefaultControlsSettings.switchValue,
    },
  ],
  media: {
    audio: [
      {
        autoplay: 'Autoplay',
        autoplayValue: DefaultMediaSettings.audio.autoplayValue,
      },
      {
        showEqualizer: 'Show Equalizer',
        showEqualizerValue: DefaultMediaSettings.audio.showEqualizerValue,
      },
      {
        defaultVolume: 'Default Volume',
        defaultVolumeOptions: [
          { value: '0%', label: '0%' },
          { value: '50%', label: '50%' },
          { value: '100%', label: '100%' },
        ],
        defaultVolumeValue: DefaultMediaSettings.audio.defaultVolumeValue,
      },
    ],

    video: [
      {
        autoplay: 'Autoplay',
        autoplayValue: DefaultMediaSettings.video.autoplayValue,
      },
      {
        muted: 'Muted',
        mutedValue: DefaultMediaSettings.video.mutedValue,
      },
      {
        videoQuality: 'Video Quality',
        videoQualityValue: DefaultMediaSettings.video.videoQualityValue,
      },
      {
        enableSubtitle: 'Enable Subtitle',
        enableSubtitleValue: DefaultMediaSettings.video.enableSubtitleValue,
      },
      {
        loopVideo: 'Loop Video',
        loopVideoValue: DefaultMediaSettings.video.loopVideoValue,
      },
      {
        enableFullscreen: 'Enable Fullscreen',
        enableFullscreenValue: DefaultMediaSettings.video.enableFullscreenValue,
      },
      {
        enablePIP: 'Enable picture-in-picture',
        enablePIPValue: DefaultMediaSettings.video.enablePIPValue,
      },
    ],
  },
  icons: [
    {
      iconVariant: 'Icons',
      iconVariantValue: DefaultIconSettings.iconVariantValue,
    },
    {
      iconWeight: 'Weight',
      iconWeightValue: DefaultIconSettings.iconWeightValue,
    },
  ],
};
