<div class="sch-tabs" [ngClass]="{ 'fill': fill || justified, }">
  <div *ngFor="let tab of tabs; let i = index" class="sch-tabs-item" [ngClass]="{
      active: tab.active,
      justify: this.justified,
      'hover:bg-primary': !tab.active && this.activeTabStyle.hoverColor,
      'bg-primary': tab.active && this.activeTabStyle.backgroundColor,
      'rounded-3': tab.active && this.activeTabStyle.backgroundColor,
      'border-primary': tab.active && this.activeTabStyle.borderBottomColor,
      'inactive-border': !tab.active && inactiveTabBorderBottom
      }">
    <i *ngIf="tabIcon" [sch-icon]="tabIcon" class="tab-icon" [ngClass]="{'text-primary': tab.active}"></i>

    <p class="sch-tabs-title m-0 text-gray" (click)="selectTab(i)" [innerHTML]="tab.title" [ngClass]="{'text-primary': tab.active && !activeTabStyle.backgroundColor, 
      'text-white': tab.active && activeTabStyle.backgroundColor}">
    </p>

    <div *ngIf="tabIcon" class="sch-tab-count"
      [ngClass]="{'bg-primary': tab.active && this.activeTabStyle.borderBottomColor}">
      0
    </div>
  </div>
</div>
<ng-content></ng-content>