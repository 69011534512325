import { CommonModule } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';
import { Icon, IconDirective } from '@seech/shared-ng';

export interface TreeNode {
  name: string;
  icon?: Icon;
  childCount: number;
  editable: boolean;
  deletable: boolean;
  children?: TreeNode[];
  payload?: any; // Payload can be plain text, HTML, Angular component, etc.
}

@Component({
  standalone: true,
  selector: 'seech-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss'],
  imports: [CommonModule, IconDirective],
})
export class TreeViewComponent {
  @Input() nodes?: TreeNode[] = [];
  @Input() defaultIcon: Icon = 'folder-open-fill';
  @Input() expanded = false;
  @Input() payloadTemplate?: TemplateRef<any>;

  toggleExpandCollapse(node: TreeNode): void {
    node['expanded'] = !node['expanded'];
  }
}
