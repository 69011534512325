import {
  Directive,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { ButtonDirective as SharedButtonDirective } from '@seech/shared-ng';
import { ControlsConfigService } from '../../injection/controls-config.service';

@Directive({
  selector: '[sch-button]',
  standalone: true,
})
export class ButtonDirective extends SharedButtonDirective {

  constructor(el: ElementRef, 
              rdr: Renderer2, 
              sanitizer: DomSanitizer, 
              private configService: ControlsConfigService, 
            ) {
    super(el, rdr, sanitizer);
    const config = this.configService.getConfig();
    if (config?.buttonShape) this.shape = config.buttonShape;
  }
}
