import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
  selector: 'seech-star-rating',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StarRatingComponent),
      multi: true,
    },
  ],
})
export class StarRatingComponent implements OnInit, ControlValueAccessor {
  @Input() rate = 0;
  @Input() starCount = 5;
  @Input() reviewCount?: string;
  @Input() disabled = false;
  @Input() width="11";
  @Input() height="11";
  @Input() gap = "0";
  @Input() color = "";

  stars: number[] = [];

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  private onChange: (value: number) => void = () => { };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched: () => void = () => { };

  ngOnInit(): void {
    this.stars = Array(this.starCount).fill(0).map((x, i) => i + 1);
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.rate = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onValueChange(value: number): void {
    this.rate = value;
    this.onChange(value);
    this.onTouched();
  }

  setRating(star: number): void {
    if (this.rate === star) {
      this.onValueChange(0); // Reset to zero if the same star is clicked
    } else {
      this.onValueChange(star);
    }
  }
}
