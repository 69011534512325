import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {
  MdbDatepickerComponent,
  MdbDatepickerModule,
} from 'mdb-angular-ui-kit/datepicker';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { ValidationService } from '../../services/validation.service';
import { InputDirective } from '../../directives';
import { IconDirective, TextboxVariant } from '@seech/shared-ng';
import { ControlsConfigService } from '../../injection/controls-config.service';
import { TEXTBOX_VARIANT } from '../../constants';
import { Alignment } from '../../models';

export type DateFormat =
  | 'dd/mm/yyyy'
  | 'mm/dd/yyyy'
  | 'yyyy/mm/dd'
  | 'dd/mm/yy'
  | 'mm/dd/yy'
  | 'yy/mm/dd';

@Component({
  selector: 'seech-date',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MdbDatepickerModule,
    ReactiveFormsModule,
    MdbFormsModule,
    IconDirective,
    InputDirective,
  ],
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateComponent),
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateComponent
  implements OnInit, ControlValueAccessor, Validator, OnChanges
{
  @ViewChild('datepicker', { static: true })
  datepicker!: MdbDatepickerComponent;

  @Input() value: any = '';
  @Input() minDate: any = '';
  @Input() maxDate: any = '';
  @Input() label?: string;
  @Input() placeholder = 'Select date';
  @Input() required = false;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() inline = false;
  @Input() id = 'date';
  @Input() format: DateFormat = 'mm/dd/yyyy';
  @Input() disablePast = false;
  @Input() disableFuture = false;
  @Input() variant!: TextboxVariant;
  @Input() autocomplete: 'on' | 'off' = 'off';
  @Input() alignIcon: Alignment = 'right';

  @Output() valueChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() opened: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() valid: EventEmitter<boolean> | any = new EventEmitter<boolean>();

  date = new Date();
  pastDates = this.date;
  futureDates = this.date;
  showDateIcon = true;
  textboxVariants = TEXTBOX_VARIANT;

  // Implementation for ControlValueAccessor
   
  private onChange = (value: any) => {};
   
  private onTouched = () => {};

  constructor(
    private dateValidationService: ValidationService,
    private cdr: ChangeDetectorRef,
    private configService: ControlsConfigService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['disabled'] || changes['readonly']) {
      if(changes['disabled']?.currentValue || changes['readonly']?.currentValue){
        this.showDateIcon = false;
      }
      else {
        this.showDateIcon = true;
      }
    }
  }

  ngOnInit(): void {
    this.pastDates.setDate(this.pastDates.getDate() - 1);
    this.futureDates.setDate(this.futureDates.getDate() + 1);

    if (!this.variant) {
      const config = this.configService.getConfig();
      this.variant = config?.textboxVariant ? config.textboxVariant : 'classic';
    }
  }

  onDateChanged(event: any): void {
    this.value = this.dateValidationService.reformatDate(event, this.format);
    this.valueChange.emit(this.value);
    this.onChange(this.value);
    this.onTouched();
    this.valid.emit(this.value !== '');
  }

  onDateOpened() {
    // console.log('opened');
    this.opened.emit(true);
    // this.cdr.detectChanges();
  }

  openDatepicker(): void {
    this.datepicker.open();
  }

  onDateClosed() {
    this.closed.emit(true);
    // this.opened.emit(false);
    // console.log(e);
  }

  writeValue(value: any): void {
    if (value) {
      this.value = this.dateValidationService.reformatDate(value, this.format);
    }
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  isFormatComplete = false;

  formatDate(event: any): void {
    const input = event?.target?.value;
    // Ensure only numbers and slashes are allowed
    const formattedInput = input?.replace(/[^0-9/]/g, '');
    event.target.value = formattedInput;
    this.value = formattedInput;
    this.onChange(this.value);
    this.onTouched();
    this.valueChange.emit(this.value);

    if (
      (formattedInput.length === 2 || formattedInput.length === 5) &&
      event.key !== 'Backspace'
    ) {
      event.target.value += '/';
      this.value = event.target.value;
      this.onChange(this.value);
      this.onTouched();
      this.valueChange.emit(this.value);
    }

    // Check if format is complete
    this.isFormatComplete = formattedInput.length === 10;

    // Validate the date format
    if (this.isFormatComplete) {
      if (!this.getDateValidity()) {
        this.isFormatComplete = false;
        this.valid.emit(false);
        // You can show an error message here if needed
      } else {
        this.valid.emit(true);
        this.isFormatComplete = true;
      }
    }
  }

  private getDateValidity(): boolean {
    return this.dateValidationService.validateDate(this.value, this.format);
  }

  // Implementation for Validator
   
  validate(control: AbstractControl): ValidationErrors | null {
    const valueIsRequired = control.hasValidator(Validators.required);
    const requiredError = { required: true };
    if (!this.value && valueIsRequired) {
      return requiredError;
    }

    if (!this.getDateValidity() && this.value && valueIsRequired) {
      return { invalidDate: true };
    }
    return null;
  }

  convertToUpperCase(text) {
    return text.toUpperCase();
}

}
