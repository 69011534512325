<ul class="tree-view">
    <li *ngFor="let node of nodes" class="tree-node">
        <div class="node-content" (click)="toggleExpandCollapse(node)">
            <i [sch-icon]="node['expanded'] ? 'collapse' : 'chevron-right'" alt="Collapse"
                class="toggle-icon text-primary">
            </i>

            <i [sch-icon]="node.icon || defaultIcon" alt="Icon" class="node-icon text-primary"></i>

            <div class="d-flex align-items-center text-center gap-1">
                <p class="node-name mb-0"> {{ node.name }} </p>
                <p class="node-child-count mb-0" *ngIf="node.childCount">({{ node.childCount }})</p>
            </div>
        </div>

        <!-- Injected Payload -->
        <div *ngIf="node['expanded'] && node.payload" class="node-payload">
            <!-- <div *ngIf="node.payload" class="node-payload"> -->
            <ng-container *ngIf="payloadTemplate; else defaultPayload">
                <ng-container *ngTemplateOutlet="payloadTemplate; context: { $implicit: node.payload }"></ng-container>
            </ng-container>
            <ng-template #defaultPayload>
                <div [innerHTML]="node.payload"></div> <!-- For HTML or text-based payloads -->
            </ng-template>
        </div>

        <!-- Child Nodes -->
        <ul *ngIf="node.children?.length && (node['expanded'] || expanded)" class="child-nodes">
            <seech-tree-view [nodes]="node.children" [defaultIcon]="defaultIcon" [expanded]="expanded"
                [payloadTemplate]="payloadTemplate" />
        </ul>
    </li>
</ul>