<header *ngIf="showHeader" class="stepper-header" [class]="headerClass"
  [style.background-color]="headerBackgroundColor">
  <div class="w-100 d-flex flex-nowrap gap-3 gap-lg-5">
    <div class="d-flex align-items-center gap-3 gap-lg-4" [class.flex-grow-1]="i !== _steps.length - 1"
      *ngFor="let step of _steps; let i = index;">
      <div mdbRipple class="d-flex align-items-center gap-2 py-2 px-lg-4 sch-stepper-item" (click)="selectStep(i)">
        <p class="sch-stepper-count m-0" [ngClass]="{'border-primary': isActive(i), 'bg-primary':isActive(i)}">
          {{i+1}}
        </p>
        <p class="header-label m-0" [class.text-primary]="isActive(i)">{{step.label ? step.label :
          'Step '+(i+1)}} </p>
      </div>
      <hr *ngIf="i !== _steps.length - 1" class="header-line  flex-grow-1"
        [ngClass]="{'border-primary': isActive(i), 'bg-primary': isActive(i)}">
    </div>
  </div>
</header>
<ng-container [ngTemplateOutlet]="selected ? selected.content : null"></ng-container>