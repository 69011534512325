<div class="sch-input">
    <label *ngIf="label && variant === textboxVariants.CLASSIC" class="sch-input-label {{variant}}" for="{{ id }}"
        [attr.readonly]="readonly" [attr.disabled]="disabled">
        {{ label }}
    </label>

    <div (click)="readonly || disabled ? null : toggleDropdown()" class="position-relative">
        <input type="text" class="sch-input-field {{variant}} cursor-pointer" id="{{ id }}" [value]="selectedLabel"
            [placeholder]="readonly ? readonlyplaceholder : placeholder" (input)="onInput($event)" [readonly]="readonly"
            [disabled]="disabled" [class.has-value]="selectedLabel" [class.has-placeholder]="placeholder"
            [autocomplete]="autocomplete" />

        <label *ngIf="label && variant !== textboxVariants.CLASSIC" class="sch-input-label {{variant}}" for="{{ id }}"
            [attr.readonly]="readonly" [attr.disabled]="disabled">
            {{ label }}
        </label>

        <i *ngIf="!readonly" [id]="id" [sch-icon]="showDropdown ? 'expand' : 'collapse'"
            class="sch-input-icon sch-select-icn"></i>
    </div>

    <div *ngIf="!(readonly && disabled) && showDropdown && filteredOptions.length > 0" class="sch-select-dropdown"
        #dropdownMenu sch-scroll-end-notifier [offset]="threshold" (scrollEnd)="onScrollEnd()">
        <ng-container *ngFor="let option of filteredOptions; index as i">
            <div *ngIf="option.title" class="option-title">
                {{ option.title }}
            </div>

            <ng-container *ngIf="option.options">
                <ng-container *ngFor="let nestedOption of option.options">
                    <div *ngIf="!(!showSelected && selectedValue === nestedOption.value) && !nestedOption.hideOption"
                        class="sch-select-option" [ngClass]="{ active: selectedValue === nestedOption.value }"
                        (click)="selectOption(nestedOption)">
                        <div class="d-flex my-1">
                            <div class="flex-grow-1 sch-option-key">{{ nestedOption.label }}</div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>

    </div>
</div>