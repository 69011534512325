<div class="position-relative w-100 h-100 gold-layout-container">
  <div class="seech-layout-backdrop" (click)="onBackdropClicked()" *ngIf="hasBackdrop"
    [class.seech-layout-shown]="isShowingBackdrop()"></div>

  <header class="layout-header bg-body position-absolute top-0 left-0 right-0">
    <ng-content select="[header]">
    </ng-content>
  </header>

  <ng-content select="seech-side-nav"></ng-content>

  <ng-content select="seech-layout-content">
  </ng-content>

  <footer *ngIf="deviceDisplayIsMobile"
    class="layout-footer bg-body position-fixed fixed-bottom left-0 right-0 bottom-0">
    <ng-content select="[footer]">
    </ng-content>
  </footer>
</div>