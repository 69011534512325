<div class="sch-input">
  <label
    *ngIf="label && variant === textboxVariants.CLASSIC"
    class="sch-input-label {{ variant }}"
    for="{{ id }}"
    [attr.readonly]="readonly"
    [attr.disabled]="disabled"
  >
    {{ label }}
  </label>

  <div class="position-relative">
    <input
      type="text"
      id="{{ id }}"
      sch-input
      class="sch-input-field has-placeholder {{ variant }}"
      [mdbTimepicker]="timepicker"
      required
      [value]="value.h || value"
      [placeholder]="
        readonly ? readonlyplaceholder : format24 ? 'HH:MM' : 'HH:MM AM/PM'
      "
      [class.has-value]="value"
      [class.has-icon]="showTimeIcon"
      [ngClass]="{ 'align-icon-start': alignIcon === 'left' }"
      (input)="formatTime($event)"
      [readonly]="readonly"
      [disabled]="disabled"
      autocomplete="{{ autocomplete }}"
      (blur)="onBlur()"
    />

    <label
      *ngIf="label && variant !== textboxVariants.CLASSIC"
      class="sch-input-label {{ variant }}"
      for="{{ id }}"
      [attr.readonly]="readonly"
      [attr.disabled]="disabled"
    >
      {{ label }}
    </label>

    <i
      *ngIf="showTimeIcon"
      [sch-icon]="'clock'"
      class="sch-input-icon"
      [id]="id"
      (click)="openTimePicker()"
    ></i>

    <mdb-timepicker
      #timepicker
      [maxTime]="maxTime"
      [minTime]="minTime"
      [format24]="format24"
      [inline]="useInline"
      (timeChange)="onTimeChanged($event)"
      (opened)="onOpened()"
      (closed)="onClosed()"
    ></mdb-timepicker>
  </div>
</div>
